<template>
  <div>
    <div v-if="transaction.transactionStatusTextKey">
      <Canceled
        v-if="
          transaction.transactionStatusTextKey.status === TRANSACTION_STATUSES_STEPS.CANCELLED.name
        "
      />
      <PendingCancelation
        v-else-if="
          transaction.transactionStatusTextKey.status ===
          TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name
        "
      />
      <Completed
        v-else-if="
          transaction.transactionStatusTextKey.status === TRANSACTION_STATUSES_STEPS.COMPLETED.name
        "
      />
      <ReadyForPickup
        v-else-if="
          transaction.transactionStatusTextKey.status ===
          TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
        "
      />
      <InProgress v-else />
    </div>
  </div>
</template>

<script>
import {
  Canceled,
  PendingCancelation,
  Completed,
  InProgress,
  ReadyForPickup,
} from '@galileo/assets/icons/vue/transaction-status'

import {
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_STATUSES_STEPS,
} from '@galileo/constants/transactionStatuses'

export default {
  components: {
    Canceled,
    PendingCancelation,
    Completed,
    InProgress,
    ReadyForPickup,
  },
  props: {
    transaction: Object,
  },
  setup(props) {
    return { TRANSACTION_STATUSES_STEPS, TRANSACTION_ACTIVITY_STATUSES }
  },
}
</script>
