<template>
  <div>
    <!-- Funding and converting balance -->
    <AppCardSendMoneyFinishedFunding
      v-if="transaction && !transaction.isOfflineTransaction && transaction.isFundingBalance"
      :transaction="transaction"
      @viewBankDetails="viewBankDetails"
    />
    <!-- End of Funding and converting balance  -->

    <!-- Pay from a balance -->
    <AppCardBalanceSendMoneyFinished
      v-else-if="transaction && !transaction.isOfflineTransaction && transaction.isPaidFromBalance"
    />

    <AppCard
      v-else-if="
        !isO2OContract &&
        !isVoltPending &&
        transaction &&
        transaction.deliveryMethod.enumName !== DeliveryMethod.FundsOnBalance.enumName
      "
      class="send-money-finished-card"
      sheet
    >
      <AppCardImageTitle
        :src="require('@galileo/assets/images/activity-in-progress.svg')"
        alt="Success"
        :title="$t('PageSendMoneyFinished.Title').value"
      >
        <div v-if="isCashPickup">
          <AppSpinnerBig :loading="!transaction || loadingShare"></AppSpinnerBig>
          <CashPickupTransferDetails v-if="transaction" :transaction="transaction" />
        </div>
        <p
          v-else-if="!isCashPickup && paymentMethod === 'Interac'"
          class="send-money-finished-description"
        >
          {{ $t('PageSendMoneyFinished.InteracDescription').value }}
        </p>
        <p
          v-else-if="
            !isCashPickup && transaction && transaction.paymentMethod.value === 'BankTransfer'
          "
          class="send-money-finished-description"
        >
          {{ $t('PageSendMoneyFinished.BankTransferDescription', { paymentMethodString }).value }}
        </p>
        <p v-else class="send-money-finished-description">
          {{ $t('PageSendMoneyFinished.Description').value + ' ' + recipientName }}
        </p>
      </AppCardImageTitle>
      <ButtonGroup>
        <AppButton v-if="isCashPickup" analytics-name="send-finished-share" @click="share = true">
          {{ $t('PageSendMoneyFinished.ButtonShareTransfer').value }}
        </AppButton>
        <AppButton
          v-else-if="!isCashPickup && paymentMethod === 'Interac'"
          analytics-name="view-interac-transfer-details"
          @click="viewBankDetails"
        >
          {{ $t('PageSendMoneyFinished.Interac.ViewTransfetDetailsCta').value }}
        </AppButton>
        <AppButton
          v-else-if="
            !isCashPickup && transaction && transaction.paymentMethod.value === 'BankTransfer'
          "
          analytics-name="send-finished-bank-transfer-view-details"
          @click="viewBankDetails"
        >
          {{ $t('PageSendMoneyFinished.ButtonBankTransferDetails', { paymentMethodString }).value }}
        </AppButton>

        <AppButton v-else analytics-name="send-finished-view-details" @click="viewDetails">
          {{ $t('PageSendMoneyFinished.ButtonTransferDetails').value }}
        </AppButton>
        <AppLink
          class="send-finished-send-go-to-activity"
          analytics-name="send-finished-send-go-to-activity"
          @click="onGoToActivity"
        >
          {{ $t('PageSendMoneyFinished.ButtonGoToActivity').value }}
        </AppLink>
      </ButtonGroup>
    </AppCard>

    <AppCard v-if="!isO2OContract && isOpenBanking && isVoltPending" sheet>
      <AppCardImageTitle
        :src="require('@galileo/assets/images/activity-action-required.svg')"
        :title="$t('PageSendMoneyFinished.TitleVoltPending').value"
        alt="Warning"
      >
        <div class="description">
          {{ $t('PageSendMoneyFinished.DescriptionVoltPending').value }}
        </div>
      </AppCardImageTitle>
      <ButtonGroup>
        <AppButton theme="primary" analytics-name="send-finished-view-details" @click="viewDetails">
          {{ $t('PageSendMoneyFinished.ButtonTransferDetailsVoltPending').value }}
        </AppButton>

        <AppLink
          theme="secondary"
          class="send-finished-send-go-to-activity"
          analytics-name="send-finished-send-go-to-activity"
          @click="onGoToActivity"
        >
          {{ $t('PageSendMoneyFinished.ButtonGoToActivity').value }}
        </AppLink>
      </ButtonGroup>
    </AppCard>

    <AppCard
      v-if="
        isO2OContract && transaction && !transaction.actionsRequired.includes('AwaitingBalance')
      "
      class="send-money-finished-card"
      sheet
    >
      <AppCardImageTitle
        :src="require('@galileo/assets/images/party-popper.svg')"
        :alt="$t('PageSendMoneyFinished.TitleReadyToGo').value"
        :title="$t('PageSendMoneyFinished.TitleReadyToGo').value"
      >
        <p class="send-money-finished-description">
          {{
            $t('PageSendMoneyFinished.O2OPaymentReceivedDescription', { paymentMethodString }).value
          }}
        </p>
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton analytics-name="send-finished-view-details" @click="viewDetails">
          {{ $t('PageSendMoneyFinished.ButtonO2OTransferDetails').value }}
        </AppButton>

        <AppLink
          class="send-finished-send-go-to-activity"
          analytics-name="send-finished-send-go-to-activity"
          @click="onGoToActivity"
        >
          {{ $t('PageSendMoneyFinished.ButtonGoToActivity').value }}
        </AppLink>
      </ButtonGroup>
    </AppCard>

    <AppCard
      v-if="isO2OContract && transaction && transaction.actionsRequired.includes('AwaitingBalance')"
      class="send-money-finished-card"
      sheet
    >
      <AppCardImageTitle
        :src="require('@galileo/assets/images/party-popper.svg')"
        :alt="$t('PageSendMoneyFinished.TitleAlmostThere').value"
        :title="$t('PageSendMoneyFinished.TitleAlmostThere').value"
      >
        <p class="send-money-finished-description">
          {{
            $t('PageSendMoneyFinished.O2OAwaitingPaymentDescription', { paymentMethodString }).value
          }}
        </p>
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton
          analytics-name="send-finished-bank-transfer-view-details"
          @click="viewBankDetails"
        >
          {{
            $t('PageSendMoneyFinished.ButtonO2OBankTransferDetails', { paymentMethodString }).value
          }}
        </AppButton>

        <AppLink
          class="send-finished-send-go-to-activity"
          analytics-name="send-finished-send-go-to-activity"
          @click="onGoToActivity"
        >
          {{ $t('PageSendMoneyFinished.ButtonGoToActivity').value }}
        </AppLink>
      </ButtonGroup>
    </AppCard>

    <!-- Transfer details dialog -->

    <AppModal v-model="showDetails" title="Transfer Details">
      <template #header>
        <AppCardHeader>
          <template #left>
            <AppBackButton analytics-name="send-finished-close-details" @click="hideDetails()" />
          </template>

          <template #default>
            {{ $t('PageSendMoneyFinished.TransferDetailsHeader').value }}
          </template>

          <template #right>
            <AppButton
              v-if="
                shouldShowShareButton ||
                (transaction &&
                  transaction.deliveryMethod.enumName === DeliveryMethod.MobileWallet.enumName) ||
                transaction.deliveryMethod.enumName === DeliveryMethod.CashPickup.enumName
              "
              theme="icon"
              analytics-name="send-finished-share"
              @click="share = true"
            >
              <AppIcon name=" ">
                <IconForward />
              </AppIcon>
            </AppButton>
          </template>
        </AppCardHeader>
      </template>

      <XeTransferDetails
        v-if="transaction"
        :analytics-location="analyticsLocation"
        :transaction="transaction"
      />
    </AppModal>

    <XeBankTransferDetailsModal
      v-if="transaction"
      v-model="showBankDetails"
      :transaction="transaction"
    />

    <!-- [TODO] Move to it's own component. -->
    <InformationAppModal
      v-if="transaction && transaction.bankAccountXe && !isO2OContract"
      :value="shouldDisplayBankChangeModal"
      icon="bank"
    >
      <h1 class="title">
        {{ $t('PageSendMoneyFinished.BankChangeTitle').value }}
      </h1>
      <p
        v-if="transaction && transaction.bankAccountXe"
        v-html="transaction.bankAccountXe.settlementWarningMessage"
      />

      <template #footer>
        <AppButton @click="closeBankChangeNotification">
          {{ $t('PageSendMoneyFinished.UnderstandButton').value }}
        </AppButton>
      </template>
    </InformationAppModal>

    <!-- Share modal -->

    <XeShareModal
      v-model="share"
      :title="$t('PageSendMoneyFinished.ShareHeader').value"
      @gmail="shareGmail"
      @email="shareEmail"
      @clipboard="copyClipboard"
      @hide="share = false"
    />
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import XeTransferDetails from '@galileo/components/XeTransferDetails/XeTransferDetails'
import { IconForward } from '@oen.web.vue2/icons'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import XeShareModal from '@galileo/components/XeShareModal/XeShareModal.vue'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import CashPickupTransferDetails from '@galileo/components/Views/TransferDetails/CashPickupTransferDetails'
import XeBankTransferDetailsModal from '@galileo/components/Views/TransferDetails/XeBankTransferDetailsModal'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import AppCardSendMoneyFinishedFunding from '@galileo/components/Views/SendMoney/SendMoneyFinished/AppCardSendMoneyFinishedFunding.vue'
import { useStoredOrderStore } from '@galileo/stores/storedOrder'
import { VOLT, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const.js'
import AppCardBalanceSendMoneyFinished from '@galileo/components/Views/SendMoney/SendMoneyFinished/AppCardBalanceSendMoneyFinished.vue'
import {
  useAlert,
  AppButton,
  AppLink,
  AppCard,
  AppCardHeader,
  AppIcon,
  AppSpinnerBig,
  AppCardImageTitle,
  AppModal,
  AppBackButton,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAuthStore,
  useActivityStore,
  useAppStore,
  useProfileStore,
  useSendMoneyStore,
  useQuickTransferStore,
  usePromotionStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyFinished',
  components: {
    ButtonGroup,
    AppBackButton,
    AppModal,
    AppCardImageTitle,
    AppButton,
    AppLink,
    AppCardHeader,
    AppIcon,
    AppSpinnerBig,
    AppCard,
    XeTransferDetails,
    IconForward,
    XeShareModal,
    CashPickupTransferDetails,
    XeBankTransferDetailsModal,
    InformationAppModal,
    AppCardSendMoneyFinishedFunding,
    AppCardBalanceSendMoneyFinished,
  },
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const activityStore = useActivityStore()
    const router = useRouter()
    const { add: addAlert } = useAlert()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()
    const promotionStore = usePromotionStore()

    authStore.pendingVerifyUser = null

    const analyticsLocation = SEGMENT_LOCATIONS.SEND_MONEY

    const recipientName = sendMoneyStore.getRecipientName
    const recipientFirstName = computed(() => {
      let firstName = recipientName.split(' ')
      return firstName[0]
    })
    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep
    // TODO: Bug order number does not get updated on a new transfer created
    let orderNumber = sendMoneyStore.form.createdOrderNumber
    const orderPin = sendMoneyStore.form.createdPin

    const isCashPickup = ref(false)

    const isOpenBanking = computed(
      () => sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING
    )

    const isVoltPending = computed(
      () => sendMoneyStore.voltCheckoutStatus === VOLT.STATUS.DELAYED_AT_BANK
    )

    const isO2OContract = sendMoneyStore.isO2OContract
    const paymentMethod = sendMoneyStore.form.paymentMethod
    const deliveryMethod = sendMoneyStore.form.deliveryMethod
    if (
      deliveryMethod === DeliveryMethod.CashPickup.enumName ||
      deliveryMethod === DeliveryMethod.HomeDelivery.enumName
    ) {
      isCashPickup.value = true
    }
    const copyPin = async () => {
      try {
        await navigator.clipboard.writeText(orderPin)
        addAlert($t('PageSendMoneyFinished.CopyText').value)
      } catch (error) {
        addAlert($t('PageSendMoneyFinished.ErrorText').value)
      }
    }
    const onGoToActivity = async () => {
      await router.goBackTo(
        {
          name: 'Activity',
        },
        true
      )
    }

    const showDetails = ref(false)
    const viewDetails = () => {
      showDetails.value = true
    }
    const hideDetails = () => {
      showDetails.value = false
    }

    const share = ref(false)
    const transaction = ref(null)

    const shouldDisplayBankChangeModal = ref(null)

    const closeBankChangeNotification = () => {
      shouldDisplayBankChangeModal.value = false
      sendMoneyStore.bankDetailsChangedIsVisible = false
    }

    onBeforeMount(async () => {
      if (quickTransferStore.isProcessingTransaction) {
        quickTransferStore.isProcessingTransaction = false
      }
      const storedOrder = useStoredOrderStore().getStoredOrder
      try {
        orderNumber = sendMoneyStore.form.createdOrderNumber

        transaction.value = await activityStore.getTransactionDetails(orderNumber)

        if (transaction.value?.launchpadTransferId === storedOrder?.transferId) {
          useStoredOrderStore().clearStoredOrder()
        }

        if (
          transaction.value.bankAccountXe &&
          transaction.value.bankAccountXe.settlementWarningMessage
        ) {
          // feedback trigger
          sendMoneyStore.bankDetailsChangedIsVisible = true
          shouldDisplayBankChangeModal.value =
            !!transaction.value.bankAccountXe?.settlementWarningMessage
        } else {
          sendMoneyStore.bankDetailsChangedIsVisible = false
        }

        // call the GTM Tg information here
        useProfileStore().getTransactionGTMData()
        sendMoneyStore.activeStepIdx = null
      } catch (ex) {
        appStore.logException('Exception during getting transactions', ex)
        appStore.messageBoxGenericError()
      }
    })

    const loadingShare = ref(false)
    const shareGmail = () => {
      activityStore.shareGmail(transaction.value, loadingShare)
      share.value = false
    }
    const shareEmail = () => {
      activityStore.shareEmail(transaction.value, loadingShare)
      share.value = false
    }
    const copyClipboard = async () => {
      try {
        activityStore.shareClipboard(transaction.value, loadingShare)
        addAlert($t('PageSendMoneyFinished.CopyText').value)
      } catch (error) {
        // TODO: Should the snack alert be used to show errors?
        addAlert($t('PageSendMoneyFinished.ErrorText').value)
      }
      share.value = false
    }

    const showBankDetails = ref(false)
    const viewBankDetails = () => {
      showBankDetails.value = true
    }

    const paymentMethodString = computed(() => {
      const userCountry = authStore.userProfile.country
      if (userCountry === 'US') {
        return $t('PageSendMoneyFinished.WireTransfer').value
      } else {
        return $t('PageSendMoneyFinished.BankTransfer').value
      }
    })

    onBeforeUnmount(() => {
      if (authStore.userProfile?.country === 'MY' && authStore.isCorporateAccount) {
        authStore.refreshUser()
      }
    })

    sendMoneyStore.bankDetailsChangedIsVisible = true

    return {
      shouldShowShareButton: authStore.isConsumerAccount,
      onGoToActivity,
      viewDetails,
      showDetails,
      hideDetails,
      activeStep,
      orderNumber,
      orderPin,
      isCashPickup,
      isO2OContract,
      copyPin,
      loadingShare,
      share,
      shareGmail,
      shareEmail,
      copyClipboard,
      $t,
      transaction,
      recipientFirstName,
      analyticsLocation,
      recipientName,
      showBankDetails,
      viewBankDetails,
      paymentMethodString,
      shouldDisplayBankChangeModal,
      closeBankChangeNotification,
      isOpenBanking,
      isVoltPending,
      paymentMethod,
      DeliveryMethod,
    }
  },
}
</script>

<style scoped>
.send-money-finished-card {
  @apply mb-8;
}
.send-money-finished-description {
  @apply pb-4 pt-2 text-base leading-6 font-normal;
}
.send-finished-send-go-to-activity {
  @apply pt-7;
}
::v-deep .card-image-title img {
  width: 40px;
}
::v-deep .card-image-title {
  .card-image-title-title {
    @apply leading-8 text-2xl font-medium;
  }
}
</style>
