<template>
  <div class="summary-header">
    <TransactionStatusIcon :transaction="trx" />
    <h3 class="leading-4">
      {{ $t(trx.transactionStatusTextKey.textKey).value }}
    </h3>
    <h2 class="amount-title">
      {{ `${formatCurrency(trx.currencyTo, trx.amountTo)}  ${trx.currencyTo}` }}
    </h2>
    <h3 v-if="isFundingBalance" class="leading-4">
      {{ trx.currencyTo }} {{ trx.deliveryMethod.text }}
    </h3>
    <h3 v-else class="leading-4">
      <EllipsisWrapper :value="trx.recipientFirstName">
        <span>{{ trx.recipientFirstName }}</span>
      </EllipsisWrapper>
    </h3>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'

import { useCountriesStore, useI18nStore } from '@galileo/stores'
import { formatCurrency } from '@galileo/composables/useCurrency'

import TransactionStatusIcon from '@galileo/components/Views/TransferDetails/TransactionStatusIcon.vue'
import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'

export default {
  name: 'XeTransferDetailsSummaryHeader',
  components: {
    EllipsisWrapper,
    TransactionStatusIcon,
  },
  props: {
    transaction: {
      type: TransactionDetails,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const countriesStore = useCountriesStore()

    let trx = computed(() => props.transaction)
    const isFundingBalance = computed(() => trx.value.isFundingBalance)

    const getCountryName = (countryIsoCode) => {
      const country = countriesStore.getCountryByIsoCode(countryIsoCode)
      if (country) {
        return country.text
      }
      return countryIsoCode
    }

    return {
      $t,
      trx,
      getCountryName,
      isFundingBalance,
      formatCurrency,
    }
  },
}
</script>

<style scoped>
.headline {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #333333;
}
.amount-title {
  @apply text-center headline text-2xl font-semibold;
}
.summary-header {
  @apply flex flex-col gap-3 justify-center justify-items-center items-center mb-6 leading-5;
}
</style>
