<template>
  <div class="text-secondary-text">
    <p>
      {{ $t('ComponentCashPickupTransferDetails.NextStepsFor').value }}
      <EllipsisWrapper :value="transaction.recipientFullName">
        <span>{{ transaction.recipientFullName }}</span>
      </EllipsisWrapper>
      {{ $t('ComponentCashPickupTransferDetails.NextStepsForText').value }}:
    </p>
    <div class="cash-pickup-list mt-4">
      <ul>
        <li>
          <SendMoneyCashPickupLocation
            :country="transaction.countryTo"
            :currency="transaction.currencyTo"
            :cityName="transaction.recipientAddressCity"
            :stateName="transaction.recipientAddressState"
            :agent="transaction.agentTo"
            :agentLocation="transaction.agentToLocation"
            :showLink="false"
          />
        </li>
        <li>{{ $t('ComponentCashPickupTransferDetails.ShowPin').value }}: {{ transaction.pin }}</li>
        <li>{{ $t('ComponentCashPickupTransferDetails.PresentValidId').value }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { SendMoneyCashPickupLocation } from '@galileo/components/Views/SendMoney/Organisms'
import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'
export default {
  name: 'CashPickupTransferDetails',
  components: {
    SendMoneyCashPickupLocation,
    EllipsisWrapper,
  },
  emits: [],
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { $t } = useI18nStore()
    return { $t }
  },
}
</script>

<style scoped>
.cash-pickup-list {
  @apply px-4 mb-8 text-left shadow-ria-1;
  border: 1px solid #dddddd;
  border-radius: 16px;

  ul {
    @apply list-disc mx-4 mt-6;
  }
  li {
    @apply mb-6;
  }
}
</style>
