import { Ref, ref } from '@vue/composition-api'

import { useI18nStore } from '@galileo/stores'
import useTimelineCommon from '@galileo/composables/useTransferDetails/shared'
import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { TRANSACTION_ACTIVITY_STATUSES } from '@galileo/constants/transactionStatuses'

export default (callbacks: any = null) => {
  const {
    format,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    availableTimelineColors,
    warningCTA_callback,
  }: any = useTimelineCommon()

  const doFormat = (dateString: string) => {
    if (!dateString) return ''
    return format(dateString).value
  }

  const fundsOnBalanceSteps: Ref<Step[]> = ref([])
  const currentFundsOnBalanceStep = ref(0)
  const showBankDetails = ref(false)
  const showBankTransferDurationText = ref(false)

  const reset = () => {
    showBankDetails.value = false
    showBankTransferDurationText.value = false
  }

  const parseFundsOnBalanceTransactionDetails = (transaction: TransactionDetails) => {
    fundsOnBalanceSteps.value = getTransactionSteps(transaction)
  }

  const getTransactionSteps = (transaction: TransactionDetails): Step[] => {
    const steps: Ref<Step[]> = ref([])
    const { $t } = useI18nStore()

    const dateCreated =
      doFormat(transaction.dateCreated) || $t('TransactionTimelineBalance.DateCreated').value

    const dateReceived =
      doFormat(transaction.datePaymentReceived) ||
      $t('TransactionTimelineBalance.DateReceived').value

    const getConvertingBalanceInProgressSteps = () => {
      const step1 = {
        text: $t('TransactionTimelineBalance.YouSetUpYourConversion').value,
        description: dateCreated,
      }
      const step2 = {
        text: $t('TransactionTimelineBalance.ConversionComplete').value,
      }
      return [step1, step2]
    }

    const getConvertingBalanceCompletedSteps = () => {
      const step1 = {
        text: $t('TransactionTimelineBalance.YouSetUpYourConversion').value,
        description: dateCreated,
      }
      const step2 = {
        text: $t('TransactionTimelineBalance.ConversionComplete').value,
      }
      return [step1, step2]
    }

    const getFundingBalanceCompletedSteps = () => {
      const step1 = {
        text: $t('TransactionTimelineBalance.YouSetUpYourFundingTransfer').value,
        description: dateCreated,
      }
      const step2 = {
        text: $t('TransactionTimelineBalance.WeveGotYourCurrency', {
          currency: transaction.currencyFrom,
        }).value,
        description: dateReceived,
      }
      const step3 = {
        text: $t('TransactionTimelineBalance.YourFundsAreAvailable').value,
      }
      return [step1, step2, step3]
    }

    const getFundingBalanceInProgressSteps = () => {
      const step1 = {
        text: $t('TransactionTimelineBalance.YouSetUpYourFundingTransfer').value,
        description: dateCreated,
      }
      const step2 = {
        text: $t('TransactionTimelineBalance.WereProcessingYourFunds').value,
        description: dateReceived,
      }
      const step3 = {
        text: $t('TransactionTimelineBalance.YourFundsAreAvailable', {
          currency: transaction.currencyTo,
        }).value,
      }
      return [step1, step2, step3]
    }

    const getFundingBalanceActionRequiredSteps = () => {
      if (transaction.paymentMethod?.value === PAYMENT_METHODS.BANK_TRANSFER) {
        const step1 = {
          text: $t('TransactionTimelineBalance.YouSetUpYourFundingTransfer').value,
          description: dateCreated,
        }
        const step2 = {
          text: $t('ComponentXeTransferDetails.WaitingForFunds').value,
        }
        const step3 = {
          text: $t('TransactionTimelineBalance.YourFundsAreAvailable', {
            currency: transaction.currencyTo,
          }).value,
        }
        return [step1, step2, step3]
      } else {
        const step1 = {
          text: $t('TransactionTimelineBalance.YouSetUpYourFundingTransfer').value,
          description: dateCreated,
        }
        const step2 = {
          text: $t('PageActivity.StatusActionRequired').value,
        }
        const step3 = {
          text: $t('TransactionTimelineBalance.YourFundsAreAvailable', {
            currency: transaction.currencyTo,
          }).value,
        }
        return [step1, step2, step3]
      }
    }

    const status = getBalanceTransactionStatus(transaction)

    switch (status) {
      case 'ConvertingBalanceInProgress':
        currentFundsOnBalanceStep.value = 0
        steps.value = getConvertingBalanceInProgressSteps()
        break
      case 'ConvertingBalanceCompleted':
        currentFundsOnBalanceStep.value = 1
        timelineColor.value = availableTimelineColors.GREEN
        steps.value = getConvertingBalanceCompletedSteps()
        break
      case 'FundingBalanceCompleted':
        currentFundsOnBalanceStep.value = 2
        timelineColor.value = availableTimelineColors.GREEN
        steps.value = getFundingBalanceCompletedSteps()
        break
      case 'FundingBalanceInProgress':
        currentFundsOnBalanceStep.value = 1
        steps.value = getFundingBalanceInProgressSteps()
        break
      case 'FundingBalanceActionRequired':
        currentFundsOnBalanceStep.value = 1
        timelineColor.value = availableTimelineColors.YELLOW
        timelineWarningMessage.value = $t('ComponentXeTransferDetails.UseXeBankDetails').value
        warningCTA_Text.value = $t('ComponentXeTransferDetails.ViewXeBankDetails').value
        warningCTA_callback.value = callbacks?.value.SHOW_BANK_DETAILS
        steps.value = getFundingBalanceActionRequiredSteps()
        break
    }

    return steps.value
  }

  return {
    parseFundsOnBalanceTransactionDetails,
    fundsOnBalanceSteps,
    currentFundsOnBalanceStep,
    showBankDetails,
    showBankTransferDurationText,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
    reset,
  }
}

type Step = {
  text: string
  description?: string
  linkText?: string
  linkCallback?: string
}

const getBalanceTransactionStatus = (
  transaction: TransactionDetails
): BalanceTransactionStatus | null => {
  const paymentMethod = transaction.paymentMethod?.value

  const isFundingBalance = paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE
  const isConvertingBalance = paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
  const activityStatus = transaction.transactionActivityStatus

  const valueDate = new Date(transaction.valueDate)
  const now = new Date()
  if (valueDate.valueOf > now.valueOf) {
    return 'ConvertingBalanceInProgress'
  }

  if (isFundingBalance) {
    if (activityStatus === TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name) {
      return 'FundingBalanceActionRequired'
    }
    if (activityStatus === TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name) {
      return 'FundingBalanceInProgress'
    }
    if (activityStatus === TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name) {
      return 'FundingBalanceCompleted'
    }
  } else if (isConvertingBalance) {
    if (activityStatus === TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name) {
      return 'ConvertingBalanceCompleted'
    } else {
      return 'ConvertingBalanceInProgress'
    }
  }

  return null
}

export type BalanceTransactionStatus =
  | 'FundingBalanceActionRequired'
  | 'FundingBalanceInProgress'
  | 'FundingBalanceCompleted'
  | 'ConvertingBalanceCompleted'
  | 'ConvertingBalanceInProgress'
