var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.transaction.transactionStatusTextKey)?_c('div',[(
        _vm.transaction.transactionStatusTextKey.status === _vm.TRANSACTION_STATUSES_STEPS.CANCELLED.name
      )?_c('Canceled'):(
        _vm.transaction.transactionStatusTextKey.status ===
        _vm.TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name
      )?_c('PendingCancelation'):(
        _vm.transaction.transactionStatusTextKey.status === _vm.TRANSACTION_STATUSES_STEPS.COMPLETED.name
      )?_c('Completed'):(
        _vm.transaction.transactionStatusTextKey.status ===
        _vm.TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
      )?_c('ReadyForPickup'):_c('InProgress')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }