<template>
  <div>
    <DirectDebitSendMoneyFinishedFunding
      v-if="
        transaction.paymentMethod.value === PaymentMethod.DirectDebit.enumName ||
        transaction.paymentMethod.value === PaymentMethod.OpenBanking.enumName
      "
    />

    <BankTransferSendMoneyFinishedFunding
      v-else-if="transaction.paymentMethod.value === PaymentMethod.BankTransfer.enumName"
      @viewBankDetails="$emit('viewBankDetails')"
    />
    <ConvertBalanceSendMoneyFinished
      v-else-if="transaction.paymentMethod.value === PaymentMethod.FundsOnBalance.enumName"
    />
  </div>
</template>

<script>
import { PaymentMethod } from '@galileo/models/Transaction/app'

import DirectDebitSendMoneyFinishedFunding from '@galileo/components/Views/SendMoney/SendMoneyFinished/DirectDebitSendMoneyFinishedFunding.vue'
import BankTransferSendMoneyFinishedFunding from '@galileo/components/Views/SendMoney/SendMoneyFinished/BankTransferSendMoneyFinishedFunding.vue'
import ConvertBalanceSendMoneyFinished from '@galileo/components/Views/SendMoney/SendMoneyFinished/ConvertBalanceSendMoneyFinished.vue'

export default {
  name: 'SendMoneyFinishedFunding',
  emits: ['goToActivity', 'viewBankDetails'],
  components: {
    DirectDebitSendMoneyFinishedFunding,
    BankTransferSendMoneyFinishedFunding,
    ConvertBalanceSendMoneyFinished,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { PaymentMethod }
  },
}
</script>
