<template>
  <AppCard>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/confetti.svg')"
      :title="$t('PageSendMoneyFinished.FundBalanceDirectDebitTitle').value"
      alt="Warning"
    >
      <div class="description">
        {{ $t('PageSendMoneyFinished.FundBalanceDescription').value }}
      </div>
    </AppCardImageTitle>
    <ButtonGroup class="mt-8">
      <AppButton
        analytics-name="send-finished-send-go-to-activity"
        @click="$router.push('/Activity')"
      >
        {{ $t('ComponentDirectDebitBalanceFunding.ButtonGoToActivity').value }}
      </AppButton>
      <AppLink
        class="send-finished-send-go-to-activity"
        analytics-name="send-finished-send-again"
        @click="$router.push('/fund-balance')"
      >
        {{ $t('PageSendMoneyFinished.SendAgainButton').value }}
      </AppLink>
    </ButtonGroup>
  </AppCard>
</template>

<script>
import { AppCard, AppButton, AppLink, AppCardImageTitle } from '@oen.web.vue2/ui'
import { useI18nStore } from '@galileo/stores'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'

export default {
  name: 'DirectDebitSendMoneyFinishedFunding',
  components: {
    AppCard,
    AppCardImageTitle,
    ButtonGroup,
    AppButton,
    AppLink,
  },
  setup() {
    const { $t } = useI18nStore()

    return { $t }
  },
}
</script>

<style scoped>
.send-finished-send-go-to-activity {
  @apply pt-7;
}
::v-deep .card-image-title img {
  width: 40px;
}
::v-deep .card-image-title {
  .card-image-title-title {
    @apply leading-8 text-2xl font-medium;
  }
}
</style>
