<template>
  <div class="transfer-details-container">
    <AppSpinnerBig :loading="transactionDetails.loading" />
    <XeTransferDetailsSummaryHeader
      v-if="!transactionDetails.loading"
      :transaction="transactionDetails.result"
    />

    <AppTabs v-if="trx && !transactionDetails.loading" @select="selectTab($event)">
      <AppTabsTab
        :title="$t('ComponentXeTransferDetails.TabTimeline').value"
        analytics-name="xe-transfer-details-tab-timeline"
      >
        <AppAlert v-if="showHoldOnAccountWarning" class="mb-6" theme="yellow">
          {{ $t('ComponentXeTransferDetails.AlertHoldOnAccountText1').value }}
          <br />
          {{ $t('ComponentXeTransferDetails.AlertHoldOnAccountText2').value }}
        </AppAlert>
        <AppAlert
          v-else-if="
            trx.status &&
            trx.status.value === 'AwaitingRecipientDetails' &&
            trx.actionsRequired === 'None'
          "
          class="mb-6"
          theme="yellow"
        >
          {{ $t('ComponentXeTransferDetails.AlertAwaitingRecipientDetails').value }}
        </AppAlert>
        <AppAlert
          v-else-if="trx.status && trx.transactionActivityStatus === 'PendingCancellation'"
          class="mb-6"
          theme="yellow"
        >
          {{ $t('ComponentXeTransferDetails.AlertPendingCancellation').value }}
        </AppAlert>
        <AppAlert
          v-else-if="
            trx.status &&
            trx.status.value === 'AwaitingBalance' &&
            trx.actionsRequired !== 'None' &&
            trx.actionsRequired !== 'AwaitingBalance'
          "
          class="mb-6"
          theme="yellow"
        >
          {{ $t('ComponentXeTransferDetails.ProvidePaymentAndAdditionalInfo').value }}
        </AppAlert>
        <AppAlert
          v-else-if="
            trx.status &&
            trx.status.value !== 'AwaitingBalance' &&
            trx.actionsRequired !== 'None' &&
            trx.actionsRequired !== 'AwaitingBalance'
          "
          class="mb-6"
          theme="yellow"
        >
          {{ $t('ComponentXeTransferDetails.AdditionalInfo').value }}
        </AppAlert>

        <AppAlert
          v-if="timelineVars.warningMessage"
          class="timeline-alert"
          :theme="
            timelineVars.color === 'default' ? availableTimelineColors.YELLOW : timelineVars.color
          "
        >
          <p>
            {{ timelineVars.warningMessage }}
            <a
              v-if="timelineVars.warningCta && timelineVars.warningCta_callback"
              class="timeline-warning-cta"
              @click="timelineVars.warningCta_callback"
            >
              {{ timelineVars.warningCta }}
            </a>
          </p>
        </AppAlert>

        <AppTimeline
          :class="{
            timeline: true,
            'timeline-cancelled':
              (trx.status && trx.status.value === TRANSACTION_STATUSES_STEPS.CANCELLED.name) ||
              isOpenBankingFailed,
            red: timelineVars.color === availableTimelineColors.RED,
            yellow: timelineVars.color === availableTimelineColors.YELLOW,
            green: timelineVars.color === availableTimelineColors.GREEN,
          }"
          :steps="steps"
          :current-step="currentStep"
          :show-notice="false"
        />

        <div v-if="showBankTransferDurationText" class="text-gray pl-6 text-base">
          <p>
            {{ $t('ComponentXeTransferDetails.TransfersTakeBusinessDays').value }}
          </p>
          <AppLink @click="openLearnMoreHelpdesk()">
            {{ $t('ComponentXeTransferDetails.LearnMore').value }}
          </AppLink>
        </div>
      </AppTabsTab>

      <AppTabsTab
        :title="$t('ComponentXeTransferDetails.TabDetails').value"
        analytics-name="xe-transfer-details-tab-details"
        :disabled="isOfflineContract"
      >
        <div>
          <!-- PIN -->
          <AppDescriptionList v-if="isCashPayout">
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.PINText').value }}</template>
              <template #content>
                <p>{{ trx.pin }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Reference -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{
                $t('ComponentXeTransferDetails.YourReferenceText').value
              }}</template>
              <template #content>
                <p>#{{ trx.orderNumber }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Sending -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{
                $t('ComponentXeTransferDetails.YouAreSendingText').value
              }}</template>
              <template #content>
                <p>{{ trx.amountFrom | currency(trx.currencyFrom) }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Send fee -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.SendFeeText').value }}</template>
              <template #content>
                <p>{{ trx.amountFee | currency(trx.currencyFrom) }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Send rate -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.SendRateText').value }}</template>
              <template #content>
                {{ 1 | currency(trx.currencyFrom) }}
                →
                {{ trx.rate | currency(trx.currencyTo) }}
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Total payment -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{
                $t('ComponentXeTransferDetails.TotalPaymentText').value
              }}</template>
              <template #content>
                <p>{{ trx.amountTotal | currency(trx.currencyFrom) }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Recipient gets -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template v-if="isFundingBalance" #title>
                {{ $t('ComponentXeTransferDetails.BalanceGetsText').value }}
              </template>
              <template v-else #title>
                {{ $t('ComponentXeTransferDetails.RecipientGetsText').value }}
              </template>
              <template #content>
                <p>{{ trx.amountTo | currency(trx.currencyTo) }}</p>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Recipient -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>
                {{ $t('ComponentXeTransferDetails.TransferToText').value }}
              </template>
              <template #content>
                <p v-if="isFundingBalance">{{ trx.currencyTo }} {{ trx.deliveryMethod.text }}</p>
                <EllipsisWrapper v-else :key="trx.recipientFullName" :value="trx.recipientFullName">
                  <p>{{ trx.recipientFullName }}</p>
                </EllipsisWrapper>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Country  -->
          <AppDescriptionList v-if="!isFundingBalance">
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.CountryText').value }} </template>
              <template #content> {{ getCountryName(trx.countryTo) }}</template>
            </AppDescriptionListItem>
          </AppDescriptionList>
          <!-- Wallet -->
          <AppDescriptionList
            v-if="trx.deliveryMethod && trx.deliveryMethod.enumName === 'MobileWallet'"
          >
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.WalletText').value }} </template>
              <template #content>
                {{ getCountryName(trx.agentToLocation.name) }}
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Wallet Number -->
          <AppDescriptionList v-if="mobileWalletNo">
            <AppDescriptionListItem>
              <template #title>
                {{ $t('ComponentXeTransferDetails.WalletNumberText').value }}
              </template>
              <template #content>
                {{ mobileWalletNo }}
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Payment method -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title> {{ $t('ComponentXeTransferDetails.MethodText').value }} </template>
              <template #content>
                <span v-if="isPayedFromBalance">
                  {{ trx.currencyFrom }}
                </span>
                {{ paymentMethodText }}
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>

          <!-- Receipt -->
          <AppDescriptionList>
            <AppDescriptionListItem>
              <template #title>{{ $t('ComponentXeTransferDetails.ReceiptText').value }}</template>
              <template #content>
                <div v-if="!trx.doesContractNoteExist">
                  {{ $t('ComponentXeTransferDetails.NotReady').value }}
                </div>
                <AppLink v-else class="receipt-button" @click="onShowRecieptClick">
                  <p>{{ $t('ComponentXeTransferDetails.ReceiptDownload').value }}</p>

                  <IconDownload />
                </AppLink>
              </template>
            </AppDescriptionListItem>
          </AppDescriptionList>
        </div>
      </AppTabsTab>
    </AppTabs>

    <AppModal
      v-model="showCashPickupTransferDetails"
      @hide="hideCashPickupTransferDetails"
      :title="$t('ComponentXeTransferDetails.CashPickupDetailsTitle').value"
    >
      <template #header>
        <AppCardHeader>
          {{ $t('ComponentXeTransferDetails.CashPickupDetailsTitle').value }}
          <template #right>
            <AppBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              analytics-name="activity-details-back"
              @click="hideCashPickupTransferDetails()"
            />
          </template>
        </AppCardHeader>
      </template>

      <CashPickupTransferDetails :transaction="trx" />

      <AppButton analytics-name="cash-pickup-details-share" @click="shareCashPickup">
        {{ $t('ComponentXeTransferDetails.CashPickupDetailShare').value }}
      </AppButton>
    </AppModal>

    <XeBankTransferDetailsModal
      v-if="showBankDetails"
      v-model="showBankDetails"
      :transaction="trx"
    />
  </div>
</template>

<script>
import { ref, computed, reactive, onBeforeMount } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import { getGoogleMapLink } from '@galileo/composables/useTransferHelpers'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import { CashPayoutType } from '@galileo/models/Transaction/details'
import XeTransferDetailsSummaryHeader from '@galileo/components/XeTransferDetails/XeTransferDetailsSummaryHeader/XeTransferDetailsSummaryHeader'
import CashPickupTransferDetails from '@galileo/components/Views/TransferDetails/CashPickupTransferDetails'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import {
  useI18nStore,
  useRecipientsStore,
  useCountriesStore,
  useActivityStore,
  useAppStore,
  useAnalyticsStore,
  useAuthStore,
  useTransactionStore,
} from '@galileo/stores'

import {
  useAlert,
  AppTabs,
  AppTabsTab,
  AppDescriptionList,
  AppDescriptionListItem,
  AppTimeline,
  AppSpinnerBig,
  AppLink,
  AppModal,
  AppCardHeader,
  AppButton,
  AppBackButton,
  AppAlert,
  AppAlertAction,
} from '@oen.web.vue2/ui'

import XeBankTransferDetailsModal from '@galileo/components/Views/TransferDetails/XeBankTransferDetailsModal'

import useTransferDetailsTimeline from '@galileo/composables/useTransferDetails/useTransferDetailsTimeline'
import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'
import { DELIVERY_METHODS, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import {
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_STATUSES_STEPS,
} from '@galileo/constants/transactionStatuses'

import { availableTimelineColors } from '@galileo/composables/useTransferDetails/shared'
import XeTransferModalHeader from './XeTransferModalHeader.vue'
import { TransactionStatus } from '@galileo/api/launchpad/transactions/_orderNumber/get'
import { IconDownload } from '@galileo/assets/icons/vue'

export default {
  name: 'XeTransferDetails',
  components: {
    AppTabs,
    AppTabsTab,
    AppDescriptionList,
    AppDescriptionListItem,
    AppSpinnerBig,
    AppTimeline,
    AppLink,
    AppModal,
    AppCardHeader,
    AppButton,
    AppBackButton,
    AppAlert,
    AppAlertAction,
    XeTransferDetailsSummaryHeader,
    CashPickupTransferDetails,
    InformationAppModal,
    XeBankTransferDetailsModal,
    EllipsisWrapper,
    XeTransferModalHeader,
    IconDownload,
  },
  emits: ['loaded', 'showReceipt', 'showProcessingTime', 'showShare'],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    analyticsLocation: {
      type: String,
      default: SEGMENT_LOCATIONS.ACTIVITY,
    },
  },
  setup(props, { emit, listeners }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const recipientsStore = useRecipientsStore()
    const activityStore = useActivityStore()
    const authStore = useAuthStore()

    const senderCountry = authStore.userProfile?.country

    const timelineVars = ref({
      timelineColor: null,
      warningMessage: null,
      warningCta: null,
      warningCTA_callback: null,
    })

    const { sendAgainBase, showReceipt } = useTransactionStore()

    const onSendAgainClick = async () => {
      if (listeners.sendAgain) {
        emit('sendAgain')
      } else {
        await sendAgainBase(props.transaction)
      }
    }

    const onShowRecieptClick = async () => {
      if (listeners.showReceipt) {
        emit('showReceipt')
      } else {
        await showReceipt(props.transaction)
      }
    }

    const callbacks = ref(null)

    const { getTransactionTimeline, showBankTransferDurationText, steps, currentStep } =
      useTransferDetailsTimeline(callbacks)

    const transactionDetails = reactive(
      usePromiseLazy(async (orderNumber) => await activityStore.getTransactionDetails(orderNumber))
    )

    const isOfflineContract = computed(() => {
      if (transactionDetails?.result) {
        return transactionDetails.result.isOfflineTransaction
      }
      appStore.logException('Transaction Details not retrieved')
      return false
    })

    const trx = ref({})
    const mobileWalletNo = ref(null)

    const isFundingBalance = ref(false)
    const isPayedFromBalance = ref(false)

    onBeforeMount(async () => {
      await fetchTransaction()
    })

    const paymentMethodText = computed(() => {
      let text = ''
      if (trx.value.paymentMethod) {
        if (
          senderCountry === 'AU' &&
          trx.value.currencyFrom === 'AUD' &&
          trx.value.paymentMethod.value === PAYMENT_METHODS.OPEN_BANKING
        ) {
          text = 'PayTo - ' + $t(trx.value.paymentMethod.textKey).value
        } else text = $t(trx.value.paymentMethod.textKey).value
      }

      return text
    })

    const isOpenBankingFailed = ref(false)
    const showBankDetails = ref(false)
    const onShowBankDetails = () => {
      if (!showBankDetails.value) {
        showBankDetails.value = true
      }
    }

    const fetchTransaction = async () => {
      await transactionDetails.exec(props.transaction.orderNumber)

      if (transactionDetails.result) {
        trx.value = transactionDetails.result

        isFundingBalance.value = transactionDetails.result.isFundingBalance
        isPayedFromBalance.value = transactionDetails.result.isPaidFromBalance

        if (trx.value.deliveryMethod === DeliveryMethod.MobileWallet) {
          const recipient = await recipientsStore.getMobileWalletRecipient(
            transactionDetails.result.recipientId
          )
          mobileWalletNo.value = recipient.mobileWalletAccountNo
        }

        callbacks.value = {
          CPO_INSTRUCTIONS: onCashPickupLearnMore,
          SHOW_BANK_DETAILS: onShowBankDetails,
        }

        const result = getTransactionTimeline(trx.value)

        timelineVars.value = result

        //temp workaround to set the color
        isOpenBankingFailed.value =
          trx.value.paymentMethod.value === PAYMENT_METHODS.OPEN_BANKING &&
          trx.value.transactionActivityStatus ===
            TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name &&
          (trx.value.transactionDetailStatus ===
            TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name ||
            trx.value.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name)

        emit('loaded', trx.value)
      }

      if (transactionDetails.error) {
        appStore.logException('Exception during getting transfer details', transactionDetails.error)
        appStore.messageBoxGenericError()
      }
    }

    // See location link
    const googleMapsURL = computed(() => {
      const { cashPayoutType } = trx.value
      const { latitude, longitude } = trx.value.agentToLocation || {}

      if (cashPayoutType && cashPayoutType === CashPayoutType.LocationSpecific.enumName) {
        return getGoogleMapLink({ latitude, longitude })
      }
      return null
    })
    const getCountryName = (countryIsoCode) => {
      const country = countriesStore.getCountryByIsoCode(countryIsoCode)
      if (country) {
        return country.text
      }
      return countryIsoCode
    }

    const deliveryMethod = computed(() => {
      const name = trx.value.deliveryMethod ? $t(trx.value.deliveryMethod.textKey).value : ''
      const accountNumber = trx.value.recipientMaskedAccountNumber

      return accountNumber ? `${name} ${accountNumber}` : name
    })

    const actionRequired = computed(() => {
      const status = trx.value.status
      if (status && status.value) {
        if (status.value === 'AwaitingVerification') {
          return false
        } else if (status.value === 'AwaitingRelease') {
          return true
        }
      }
      return undefined
    })

    let receiptViewed = false
    const selectTab = (tab) => {
      if (tab.analyticsName === 'xe-transfer-details-tab-details' && !receiptViewed) {
        receiptViewed = true
        let payoutMethod = 'Bank'
        if (trx.value.deliveryMethod === DeliveryMethod.CashPickup) {
          payoutMethod = 'Cash'
        } else if (trx.value.deliveryMethod === DeliveryMethod.MobileWallet) {
          payoutMethod = 'Wallet'
        }
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECEIPT_VIEWED,
          traits: {
            location: props.analyticsLocation,
            payoutMethod: payoutMethod,
          },
        })
      }
    }

    const showCashPickupTransferDetails = ref(false)
    const hideCashPickupTransferDetails = () => {
      showCashPickupTransferDetails.value = false
    }
    const onCashPickupLearnMore = () => {
      showCashPickupTransferDetails.value = true
    }

    const isCashPayout = computed(() => {
      return trx.value && trx.value.deliveryMethod === DeliveryMethod.CashPickup
    })

    const shareCashPickup = () => {
      emit('showShare')
      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSFER_DETAILS_SHARED,
        traits: {
          location: SEGMENT_LOCATIONS.TRANSFER_DETAILS,
          transactionId: trx.value.id,
          contractNumber: trx.value.orderNumber,
        },
      })
    }

    const openHowLongHelp = () => {
      appStore.openHelpDesk({
        constName: HelpDeskProperties.PAYING_FOR_YOUR_TRANSFER_BY_BANK_OR_WIRE_TRANSFER,
        article: HelpDesk[HelpDeskProperties.PAYING_FOR_YOUR_TRANSFER_BY_BANK_OR_WIRE_TRANSFER],
      })
    }

    const openLearnMoreHelpdesk = () => {
      useAppStore().openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_LONG_DO_TRANSFERS_TAKE,
        article: HelpDesk[HelpDeskProperties.HOW_LONG_DO_TRANSFERS_TAKE],
      })
    }

    const isConvertBalanceTransaction = computed(
      () =>
        trx.value.deliveryMethod.enumName === DELIVERY_METHODS.FUNDS_ON_BALANCE &&
        trx.value.paymentMethod.value === PAYMENT_METHODS.FUNDS_ON_BALANCE
    )
    const showHoldOnAccountWarning = computed(() => {
      if (isConvertBalanceTransaction) return false

      return trx.value?.status?.value === TransactionStatus.HoldOnAccount.value
    })

    return {
      steps,
      currentStep,
      trx,
      getCountryName,
      $t,
      deliveryMethod,
      transactionDetails,
      googleMapsURL,
      actionRequired,
      onSendAgainClick,
      onShowRecieptClick,
      selectTab,
      showCashPickupTransferDetails,
      hideCashPickupTransferDetails,
      onCashPickupLearnMore,
      isCashPayout,
      shareCashPickup,
      showBankTransferDurationText,
      openHowLongHelp,
      showBankDetails,
      isOfflineContract,
      mobileWalletNo,
      isOpenBankingFailed,
      senderCountry,
      paymentMethodText,
      timelineVars,
      openLearnMoreHelpdesk,
      TRANSACTION_STATUSES_STEPS,
      availableTimelineColors,
      DeliveryMethod,
      isFundingBalance,
      isPayedFromBalance,
      showHoldOnAccountWarning,
    }
  },
}
</script>
<style scoped>
.btn-container {
  @apply flex mt-8;
}

::v-deep .btn-container > .button {
  @apply w-auto;
}

::v-deep .single-btn {
  @apply w-auto;
}
::v-deep .btn-container > .button--secondary {
  color: #333333;
  background-color: #f3f4f6;
}
/* TODO: .tabs-buttons class currently has 1.5rem bottom margin? Should that be removed? */
::v-deep .description-list:first-child {
  @apply pt-0;
}
::v-deep .description-list {
  @apply py-2.5;
}
::v-deep .receipt-button {
  @apply font-medium;
  color: var(--theme-text-primary, var(--theme-color-secondary, theme('colors.blue.default')));

  .link-text {
    @apply flex items-center;
    svg path {
      fill: var(--theme-text-secondary, theme('colors.blue.default'));
      stroke: var(--theme-text-secondary, theme('colors.blue.default'));
      stroke-width: 0.01;
    }
  }
}
::v-deep .step-active .step-left {
  color: currentColor;
}

::v-deep .step-todo .step-dot .step-line-up {
  @apply bg-gray-lighter;
}
::v-deep .tab-button-inner {
  font-size: 14px;
}
.alert--yellow {
  @apply text-yellow-tertiary;
}

.timeline-cancelled,
.red {
  ::v-deep .step-dot-first {
    .step-circle {
      visibility: visible;
    }
  }
  ::v-deep .step-dot-last {
    .step-line-down {
      visibility: hidden;
    }
  }
  ::v-deep .step-circle,
  ::v-deep .step-line-down,
  ::v-deep .step-line-up {
    background-color: rgba(205, 61, 100, 1) !important;
  }
  ::v-deep .step-todo .step-dot .step-line-up,
  ::v-deep .step-active .step-dot .step-line-down,
  ::v-deep .step-todo .step-dot .step-circle {
    @apply bg-gray-lighter !important;
  }
  ::v-deep .step-active .step-left > :first-child {
    color: rgba(205, 61, 100, 1) !important;
  }
}

.yellow {
  ::v-deep .step-dot-first {
    .step-circle {
      visibility: visible;
    }
  }
  ::v-deep .step-dot-last {
    .step-line-down {
      visibility: hidden;
    }
  }
  ::v-deep .step-circle,
  ::v-deep .step-line-down,
  ::v-deep .step-line-up {
    background-color: #ca8400 !important;
  }
  ::v-deep .step-todo .step-dot .step-line-up,
  ::v-deep .step-active .step-dot .step-line-down,
  ::v-deep .step-todo .step-dot .step-circle {
    @apply bg-gray-lighter !important;
  }
  ::v-deep .step-active .step-left > :first-child {
    color: #ca8400 !important;
  }
}

.green {
  ::v-deep .step-dot-first {
    .step-circle {
      visibility: visible;
    }
  }
  ::v-deep .step-dot-last {
    .step-line-down {
      visibility: hidden;
    }
  }
  ::v-deep .step-circle,
  ::v-deep .step-line-down,
  ::v-deep .step-line-up {
    background-color: #398237 !important;
  }
  ::v-deep .step-todo .step-dot .step-line-up,
  ::v-deep .step-active .step-dot .step-line-down,
  ::v-deep .step-todo .step-dot .step-circle {
    @apply bg-gray-lighter !important;
  }
  ::v-deep .step-active .step-left > :first-child {
    color: #398237 !important;
  }
}

.timeline-alert a {
  text-decoration: underline;
}

.timeline-warning-cta {
  @apply cursor-pointer font-semibold;
}
.timeline ol li .step-left span {
  @apply font-normal !important;
}

::v-deep .step-left span {
  @apply font-normal !important;
}
</style>
