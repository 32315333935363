<template>
  <div>
    <AppModal
      v-if="transaction && transaction.bankAccountXe"
      v-model="model"
      class="bank-details"
      :title="$t('ComponentXeTransferDetails.BankDetailsTitle', { paymentMethodString }).value"
    >
      <template #header="{ dismiss }">
        <AppModalHeader>
          {{ $t('ComponentXeTransferDetails.BankDetailsTitle', { paymentMethodString }).value }}
          <template #right>
            <XeBackButton
              theme="icon"
              icon="x"
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              analytics-name="bank-transfer-details-modal-close"
              @click="dismiss"
            />
          </template>
        </AppModalHeader>
      </template>

      <AppTabs
        v-if="isInteracAvailable"
        :active-tab-index="activeTabIndex"
        @select="onSelectTab($event)"
      >
        <AppTabsTab :title="$t('ComponentXeTransferDetails.BankTransferTitle').value">
          <XeBankTransferDetailsTabContent :transaction="transaction" />
        </AppTabsTab>

        <AppTabsTab :title="$t('ComponentXeTransferDetails.InteracTitle').value">
          <p class="bank-details-text">
            {{ $t('ComponentXeTransferDetails.InteracDetailsText', { paymentMethodString }).value }}
          </p>

          <div class="mobile-card">
            <AppDescriptionList>
              <AppDescriptionListItem>
                <template #title>
                  {{ $t('ComponentXeTransferDetails.BankDetailsYouPay').value }}
                </template>
                <template #content>
                  <span> {{ transaction.amountTotal | currency(transaction.currencyFrom) }} </span>
                  <AppButton
                    class="copy-icon"
                    theme="icon"
                    @click="copyClipboard(transaction.amountTotal)"
                  >
                    <AppIcon name=" ">
                      <IconCopy />
                    </AppIcon>
                  </AppButton>
                </template>
              </AppDescriptionListItem>
            </AppDescriptionList>

            <AppDescriptionList>
              <AppDescriptionListItem>
                <template #title>
                  {{ $t('ComponentXeTransferDetails.InteracAddress').value }}
                </template>
                <template #content>
                  <span>
                    {{ interacEmail }}
                  </span>
                  <AppButton class="copy-icon" theme="icon" @click="copyClipboard(interacEmail)">
                    <AppIcon>
                      <IconCopy />
                    </AppIcon>
                  </AppButton>
                </template>
              </AppDescriptionListItem>
            </AppDescriptionList>
          </div>
        </AppTabsTab>
      </AppTabs>
      <XeBankTransferDetailsTabContent v-else :transaction="transaction" />
      <template #footer>
        <AppModalFooter
          v-if="activeTabIndex === 1 && isInteracAvailable"
          class="bank-details-buttons"
        >
          <AppButton
            theme="secondary"
            analytics-name="cash-pickup-bank-details-more"
            @click="showInteracDetails"
          >
            {{ $t('ComponentXeTransferDetails.ButtonInteracMoreInfo').value }}
          </AppButton>
        </AppModalFooter>

        <AppModalFooter v-else class="bank-details-buttons">
          <AppButton
            analytics-name="cash-pickup-bank-details-help"
            :loading="loadingBankDetailsHelp"
            @click="showBankDetailsHelp()"
          >
            {{ $t('ComponentXeTransferDetails.ButtonBankDetailsHelp').value }}
          </AppButton>
          <AppButton
            theme="secondary"
            analytics-name="cash-pickup-bank-details-more"
            @click="showBankDetailsMore = true"
          >
            <span v-if="userCountry === 'US'">
              {{ $t('ComponentXeTransferDetails.ButtonWireTransfersMore').value }}
            </span>
            <span v-else>
              {{ $t('ComponentXeTransferDetails.ButtonBankTransfersMore').value }}
            </span>
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
    <WireTransferInstructionsModal
      v-if="showBankDetailsMore"
      :transfer-finished="true"
      @close="closeWireTransferInfoModal"
    />
    <InteracTransferInstructionsModal
      v-if="showInteracDetailsMore"
      :transfer-finished="true"
      @close="closeInteracInfoModal"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import WireTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/WireTransferInstructionsModal'
import InteracTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/InteracTransferInstructionsModal'
import XeBankTransferDetailsTabContent from '@galileo/components/Views/TransferDetails/XeBankTransferDetailsTabContent'
import { IconCopy } from '@oen.web.vue2/icons'

import {
  AppTabs,
  AppTabsTab,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppDescriptionList,
  AppDescriptionListItem,
  AppButton,
  AppIcon,
  useAlert,
  useVModel,
} from '@oen.web.vue2/ui'

import { useI18nStore, useAuthStore, useAppStore } from '@galileo/stores'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

export default {
  components: {
    AppTabs,
    AppTabsTab,
    AppModal,
    AppModalHeader,
    XeBackButton,
    AppDescriptionList,
    AppDescriptionListItem,
    AppButton,
    AppIcon,
    AppModalFooter,
    IconCopy,
    WireTransferInstructionsModal,
    InteracTransferInstructionsModal,
    XeBankTransferDetailsTabContent,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const authStore = useAuthStore()
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add: addAlert } = useAlert()
    const appStore = useAppStore()

    const interacEmail = HelpDesk[HelpDeskProperties.INTERAC_EMAIL]
    const isInteracAvailable = computed(() => {
      return (
        authStore.userProfile.country === 'CA' &&
        props.transaction.currencyFrom === 'CAD' &&
        authStore.isConsumerAccount
      )
    })

    const activeTabIndex = ref(props.transaction.amountTotal <= 3000 ? 1 : 0)

    const onSelectTab = (e) => {
      if (
        e.title === $t('ComponentXeTransferDetails.InteracTitle').value ||
        e.title === 'Interac'
      ) {
        activeTabIndex.value = 1
      } else {
        activeTabIndex.value = 0
      }
    }

    const userCountry = authStore.userProfile.country

    const copyClipboard = async (value) => {
      try {
        await navigator.clipboard.writeText(value)
        addAlert($t('PageActivity.CopyText').value)
      } catch (error) {
        addAlert($t('PageActivity.ErrorText').value, error)
      }
    }

    const showInteracDetailsMore = ref(false)

    const showInteracDetails = () => {
      showInteracDetailsMore.value = true
    }

    const closeInteracInfoModal = () => {
      showInteracDetailsMore.value = false
    }

    const showBankDetailsMore = ref(false)
    const closeWireTransferInfoModal = () => {
      showBankDetailsMore.value = false
    }

    const loadingBankDetailsHelp = ref(false)
    const showBankDetailsHelp = () => {
      appStore.openHelpDesk({ loadingRef: loadingBankDetailsHelp })
    }

    const paymentMethodString = computed(() => {
      if (userCountry === 'US') {
        return $t('ComponentXeBankTransferDetailsModal.WireTransfer').value
      } else {
        return $t('ComponentXeBankTransferDetailsModal.BankTransfer').value
      }
    })

    return {
      model,
      userCountry,
      showBankDetailsMore,
      showInteracDetails,
      showInteracDetailsMore,
      loadingBankDetailsHelp,
      paymentMethodString,
      activeTabIndex,
      interacEmail,
      isInteracAvailable,
      closeWireTransferInfoModal,
      closeInteracInfoModal,
      $t,
      copyClipboard,
      showBankDetailsHelp,
      onSelectTab,
    }
  },
}
</script>

<style scoped>
.bank-details {
  scrollbar-gutter: stable both-edges; /* fixes horizontal layout shift when the modal is too long */

  ::v-deep .description-list-item-term-title {
    white-space: nowrap;
  }
  ::v-deep .description-list-item {
    @apply gap-4;
  }
  ::v-deep .description-list-item-definition {
    @apply flex items-center;
    max-width: 80%;
  }
  ::v-deep .card-content-block {
    @apply mb-0 px-4 bg-gray-lightest;
    @screen sm {
      @apply px-0 bg-white;
    }
  }
  ::v-deep .card-header {
    @apply mx-5;
  }
}

.bank-details-buttons {
  @apply flex-col;

  @screen sm {
    @apply mt-8 gap-4;
  }
}
.copy-icon {
  @apply inline-block;
  @apply ml-2;
  @apply w-6 h-6;

  ::v-deep .button-inner {
    @apply w-auto !important;
    @apply h-auto !important;
    @apply flex w-auto h-auto rounded-none;
  }
}

.mobile-card {
  @apply py-6 px-3 mb-6 gap-2 bg-white rounded-2xl shadow-ria-1;
  border: 1px solid #f3f4f6;

  @screen sm {
    @apply py-0 px-0 mb-0 gap-0 border-none shadow-none rounded-none;
  }
}

.bank-details-text {
  @apply text-secondary-text my-2;
}

/* Styling for the tab switcher */

::v-deep .tabs-buttons {
  @apply border-gray-light h-11 rounded-full items-center;
  @apply px-0 !important;
  border-width: 1px;
}
::v-deep .tabs-panel {
  @screen xs {
    @apply mx-0 px-0 !important;
  }
  @screen sm {
    @apply mx-0 px-1;
  }
}

::v-deep .tab-button {
  @apply rounded-full !important;
  @apply h-9 mx-1;
}

::v-deep .tab-button--active {
  @apply bg-blue rounded-full text-white;
}

::v-deep .tab-button--active:hover {
  @apply bg-blue !important;
}

::v-deep .tab-button--active::after {
  @apply hidden;
}

::v-deep .tab-button-inner {
  @apply rounded-full;
}
</style>
