<template>
  <AppModal v-model="model" :title="$t('ComponentXeShareModal.TitleModal').values">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <h1 v-text="title" />
        <template #right>
          <AppBackButton analytics-name="invite-friends-modal-dismiss" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <AppList>
      <RiaListItem
        v-for="option in content"
        :key="option.id"
        :analytics-name="'invite-friends-modal-option-' + option.id"
        :title="option.title"
        @itemClick="$emit(option.emit)"
      >
        <template #figure-icon>
          <Component :is="option.iconComponent"></Component>
        </template>
      </RiaListItem>
    </AppList>
  </AppModal>
</template>

<script>
import { watch } from '@vue/composition-api'
import { useI18n } from 'vue-composable'

import {
  AppBackButton,
  AppList,
  AppModal,
  AppModalHeader,
  useVModel,
  useMediaQuery,
} from '@oen.web.vue2/ui'

import { IconChevronRight, IconCopyFile, IconEmail, IconLogoGoogle } from '@oen.web.vue2/icons'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'

import { useI18nStore, useActivityStore } from '@galileo/stores'
import { SCREEN_SIZES } from '@galileo/constants/screenSize.const'

export default {
  name: 'XeShareModal',
  emit: ['input', 'clipboard', 'gmail', 'email'],
  components: {
    RiaListItem,
    AppBackButton,
    AppList,
    AppModal,
    AppModalHeader,
    IconChevronRight,
    IconCopyFile,
    IconEmail,
    IconLogoGoogle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const activityStore = useActivityStore()
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const mq = useMediaQuery()
    let content = [
      {
        id: 1,
        title: $t('ComponentXeShareModal.ButtonSendGmail').value,
        iconComponent: 'IconLogoGoogle',
        emit: 'gmail',
      },
      {
        id: 2,
        title: $t('ComponentXeShareModal.ButtonSendEmail').value,
        iconComponent: 'IconEmail',
        emit: 'email',
      },
      {
        id: 3,
        title: $t('ComponentXeShareModal.ButtonCopyClipboard').value,
        iconComponent: 'IconCopyFile',
        emit: 'clipboard',
      },
    ]
    watch(model, async (value) => {
      if (mq.current === SCREEN_SIZES.XS && value && navigator.share) {
        // Check of mobile screen and browser support for the device native sharing capabilities
        try {
          const shareData = {
            title: '',
            text: shareTransaction.value
              ? await activityStore.getTransactionShareBody(shareTransaction.value)
              : props.body,
          }

          await navigator.share(shareData)
          emit('input', false)
        } catch (error) {
          emit('input', true)
        }
      }
    })
    return {
      $t,
      model,
      content,
    }
  },
}
</script>
