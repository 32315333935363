import { ref } from '@vue/composition-api'

import {
  TRANSACTION_STATUSES_STEPS,
  TRANSACTION_ACTIVITY_STATUSES,
} from '@galileo/constants/transactionStatuses'

import { useI18nStore } from '@galileo/stores'
import useTimelineCommon from '@galileo/composables/useTransferDetails/shared'

export default (callbacks = null) => {
  const { $t } = useI18nStore()

  const {
    getTimelineFirstStepContent,
    format,
    availableTimelineColors,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
  } = useTimelineCommon()

  const cashPickupSteps = ref([])

  const currentCashPickupStep = ref(0)

  //#region get populated bank account steps
  const getPopulatedCPOSteps = (transaction) => {
    const cancelled =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.CANCELLED.name

    if (cancelled) {
      return statusCancelled(transaction)
    }

    const cancelling =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.PENDING_CANCELLATION.name

    if (cancelling) {
      return statusCancelling(transaction)
    }

    const isActionRequired = transaction.actionsRequired !== 'None'

    const transferCompleted =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name

    if (transferCompleted) {
      return statusCompleted(transaction)
    }

    const transferReadyForPickup =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name

    if (transferReadyForPickup) {
      return statusReadyForPickup(transaction)
    }

    const paymentReceived =
      !isActionRequired &&
      [
        TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name,
        TRANSACTION_STATUSES_STEPS.PAYMENT_RECEIVED.name,
        TRANSACTION_STATUSES_STEPS.READY_FOR_RELEASE.name,
        TRANSACTION_STATUSES_STEPS.AWAITING_RELEASE.name,
      ].includes(transaction.transactionDetailStatus)

    if (paymentReceived) {
      return statusPaymentReceived(transaction)
    }

    const processing =
      [
        TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name,
        TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name,
      ].includes(transaction.transactionDetailStatus) || isActionRequired

    if (processing) {
      return statusProcessing(transaction)
    }

    // const holdOnAccount = [
    //   TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name,
    //   TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name,
    //   TRANSACTION_STATUSES_STEPS.AWAITING_FUNDS_INVESTIGATION.name,
    // ].includes(transaction.transactionDetailStatus)

    // if (holdOnAccount) {
    //   return statusHoldOnAccount(transaction)
    // }
  }
  const parseCashPickupTransactionDetails = (transaction) => {
    const populatedCPOSteps = getPopulatedCPOSteps(transaction)

    cashPickupSteps.value.push(...populatedCPOSteps)
  }

  const statusCancelled = (transaction) => {
    currentCashPickupStep.value = 1

    let steps = [2]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)
    steps[1] = {
      text: $t('ComponentXeTransferDetails.transferCancelledText').value,
      description: $t('ComponentXeTransferDetails.transferCancelledDescription').value,
    }

    timelineColor.value = availableTimelineColors.RED
    timelineWarningMessage.value = $t(
      'ComponentXeTransferDetails.ChangeRequestConfirmationText'
    ).value

    return steps
  }

  const statusCancelling = (transaction) => {
    currentCashPickupStep.value = 1

    let steps = [2]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.transferCancelling').value,
      description: $t('ComponentXeTransferDetails.transferCancellingWithin24').value,
    }

    timelineColor.value = availableTimelineColors.YELLOW
    timelineWarningMessage.value = $t(
      'ComponentXeTransferDetails.ChangeRequestConfirmationText'
    ).value

    return steps
  }

  //TODO ------ update steps[2] -> - It’s ready for pickup - add correct screen for the link

  const statusCompleted = (transaction) => {
    currentCashPickupStep.value = 3
    timelineColor.value = availableTimelineColors.GREEN
    if (
      transaction.transactionActivityStatus ===
      TRANSACTION_ACTIVITY_STATUSES.CHANGE_IN_PROGRESS.name
    ) {
      currentCashPickupStep.value = 1
      timelineColor.value = availableTimelineColors.DEFAULT
      timelineWarningMessage.value = $t(
        'ComponentXeTransferDetails.ChangeRequestConfirmationText'
      ).value
    }
    let steps = [3]

    steps[0] = {
      text: $t('ComponentXeTransferDetails.yourTransferIsReady').value,
    }

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.readyForPickupText').value,
      linkText: $t('ComponentXeTransferDetails.ViewPickupInstructions').value,
      linkCallback: callbacks?.value.CPO_INSTRUCTIONS,
    }

    steps[3] = {
      text: $t('ComponentXeTransferDetails.pickedUpYourMoneyText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
      description: $t('ComponentXeTransferDetails.yourTransferIsComplete').value,
    }

    return steps
  }

  const statusReadyForPickup = (transaction) => {
    currentCashPickupStep.value = 2
    let steps = [3]

    steps[0] = {
      text: $t('ComponentXeTransferDetails.yourTransferIsReady').value,
    }

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.readyForPickupText').value,
      linkText: $t('ComponentXeTransferDetails.ViewPickupInstructions').value,
      linkCallback: callbacks?.value.CPO_INSTRUCTIONS,
    }

    steps[3] = {
      text: $t('ComponentXeTransferDetails.pickedUpYourMoneyText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
      description: $t('ComponentXeTransferDetails.yourTransferIsComplete').value,
    }

    return steps
  }

  const statusPaymentReceived = (transaction) => {
    currentCashPickupStep.value = 1
    let steps = [3]

    steps[0] = {
      text: $t('ComponentXeTransferDetails.yourTransferIsReady').value,
    }

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.readyForPickupText').value,
      linkText: $t('ComponentXeTransferDetails.ViewPickupInstructions').value,
      linkCallback: callbacks?.value.CPO_INSTRUCTIONS,
    }

    steps[3] = {
      text: $t('ComponentXeTransferDetails.pickedUpYourMoneyText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
      description: $t('ComponentXeTransferDetails.yourTransferIsComplete').value,
    }

    return steps
  }

  //processing
  const statusProcessing = (transaction) => {
    currentCashPickupStep.value = 1
    let steps = [3]

    steps[0] = {
      text: $t('ComponentXeTransferDetails.yourTransferIsReady').value,
    }

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('PageSendMoneyFinished.TitleVoltPending').value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.readyForPickupText').value,
      linkText: $t('ComponentXeTransferDetails.ViewPickupInstructions').value,
      linkCallback: callbacks?.value.CPO_INSTRUCTIONS,
    }

    steps[3] = {
      text: $t('ComponentXeTransferDetails.pickedUpYourMoneyText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
      description: $t('ComponentXeTransferDetails.yourTransferIsComplete').value,
    }

    return steps
  }

  return {
    cashPickupSteps,
    currentCashPickupStep,
    parseCashPickupTransactionDetails,
    availableTimelineColors,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
  }
}
