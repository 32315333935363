<template>
  <InformationAppModal
    v-model="shouldShowWarning"
    no-icon
    :is-loading="isLoading"
    :should-display-close-button="false"
    :should-display-back-button="true"
    :is-close-button-mobile-only="!transferFinished"
    :should-display-footer="!transferFinished"
    :title="$t('InteracTransferInstructionsModal.HowBankTitle').value"
    @close="closeModal"
    @confirm="proceed"
    class="interac-modal"
  >
    <div class="text-left mt-6 mx-2">
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">1</div>
        </div>

        <div>
          <span class="list-title">
            {{ $t('InteracTransferDetails.HowBankSubtitle1').value }}
          </span>
          <p class="list-description mb-2">
            {{ $t('InteracTransferDetails.HowBankText1').value }}
          </p>
        </div>
      </div>
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">2</div>
        </div>
        <div>
          <span class="list-title">
            {{ $t('InteracTransferDetails.HowBankSubtitle2').value }}
          </span>
          <p
            v-html="$t('InteracTransferDetails.HowBankText2', { interacEmail }).value"
            class="list-description"
          ></p>
        </div>
      </div>
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">3</div>
        </div>
        <div>
          <span class="list-title">
            {{ $t('InteracTransferDetails.HowBankSubtitle3').value }}
          </span>
          <div class="flex">
            <div class="mr-2">
              <IconExclamationMark />
            </div>
            <p class="list-description">
              {{ $t('InteracTransferDetails.HowBankText3').value }}
            </p>
          </div>
        </div>
      </div>

      <AppAlert theme="blue" class="mb-6 mt-9">
        <p class="flex">
          <span class="flex">
            <div class="mr-2">
              <IconExclamationMark />
            </div>
            <span>
              <strong class="block mb-2">
                {{ $t('ComponentXeTransferDetails.HowBankSubtitle4').value }}
              </strong>
              <span
                class="text-secondary-text"
                v-html="$t('ComponentXeTransferDetails.HowBankText4', { learnMoreTextUrl }).value"
              />
            </span>
          </span>
        </p>
      </AppAlert>
    </div>

    <template #footer>
      <AppButton @click="proceed">
        {{ modalButtonText }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { IconEmail, IconSendAlt, IconBullhorn, IconBank } from '@oen.web.vue2/icons'
import { AppIcon, AppButton, AppAlert } from '@oen.web.vue2/ui'
import { IconExclamationMark } from '@galileo/assets/icons/vue'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import {
  useAppStore,
  useSendMoneyStore,
  useAnalyticsStore,
  useI18nStore,
  useAuthStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default defineComponent({
  components: {
    AppAlert,
    InformationAppModal,
    AppIcon,
    IconEmail,
    IconSendAlt,
    IconBullhorn,
    IconBank,
    AppButton,
    IconExclamationMark,
    HelpDeskProperties,
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: null,
    },
    transferFinished: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const learnMoreTextUrl = appStore.getHelpDeskUrl(null, HelpDeskProperties.HOW_DO_I_CANCEL_MY_MONEY_TRANSFER)

    const interacEmail = HelpDesk[HelpDeskProperties.INTERAC_EMAIL]
    const country = authStore.userProfile.country

    const modalButtonText =
      props.buttonText ?? $t('ComponentXeTransferDetails.ButtonContinue').value

    const shouldShowWarning = ref(true)
    const isLoading = ref(false)

    const closeModal = () => {
      shouldShowWarning.value = false
      emit('close', false)
    }

    const proceed = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.INTERAC_TRANSFER_INSTRUCTIONS_ACCEPTED,
        traits: {
          sendAmount: sendMoneyStore.getAmountFrom(false),
          sendCurrency: sendMoneyStore.getCurrencyFrom,
          senderCountry: country,
          payoutCurrency: sendMoneyStore.form.currencyTo,
          payoutAmount: sendMoneyStore.getAmountTo(false),
          destinationCountry: sendMoneyStore.getCountryTo,
        },
      })
      shouldShowWarning.value = false
      emit('close', true)
    }

    return {
      shouldShowWarning,
      isLoading,
      closeModal,
      proceed,
      $t,
      learnMoreTextUrl,
      modalButtonText,
      interacEmail,
    }
  },
})
</script>

<style scoped>
::v-deep .card-content .card-content-block {
  @apply mb-4;
}

h1.info-title {
  @apply text-left mt-4;
  @apply font-normal !important;
}

.list-item {
  @apply flex flex-row mb-4 text-sm;
}

.list-title {
  @apply font-medium text-primary-text leading-6 inline-block pb-2;
}

.list-description {
  @apply font-normal text-secondary-text leading-4;
}

.list-icon-container {
  @apply flex-shrink-0 mr-4;
}

.list-icon {
  @apply text-blue w-full;
}

::v-deep .card.card--has-footer .card-footer {
  @apply flex-row;
  @screen sm {
    @apply flex-col-reverse;
  }
}

.desktop-button-text {
  @apply hidden;
  @screen sm {
    @apply inline-block;
  }
}

.mobile-button-text {
  @apply inline-block;
  @screen sm {
    @apply hidden;
  }
}
.bullet-point {
  @apply pt-1;
  @apply w-8 h-8 block bg-orange-light rounded-full text-center text-blue;
}
</style>
