<template>
  <div class="container">
    <div class="status-content">
      <img
        :src="require(`@galileo/assets/images/transfer-details/${statusIcon}.svg`)"
        class="m-auto"
        :alt="statusString"
      />
      <span class="text-sm text-gray font-normal"> {{ statusString }}</span>
    </div>
    <span class="text-3xl font-semibold">
      {{ formatCurrency(transaction.currencyTo, transaction.amountTo, locale) }}
      {{ transaction.currencyTo }}
    </span>
    <span class="text-md text-gray font-normal"> {{ transaction.recipientFullName }}</span>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { TRANSACTION_ACTIVITY_STATUSES } from '@galileo/constants/transactionStatuses'
import { useI18nStore, useDeviceStore } from '@galileo/stores'

import { formatCurrency } from '@galileo/composables/useCurrency'

export default defineComponent({
  components: {},
  emits: ['close'],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const locale = useDeviceStore().locale

    const status = {
      [TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name]: {
        text: $t('XeTransferModalHeader.InProgress').value,
        icon: 'icon-inprogress-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.CHANGE_IN_PROGRESS.name]: {
        text: $t('XeTransferModalHeader.ChangeInProgress').value,
        icon: 'icon-warning-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name]: {
        text: $t('XeTransferModalHeader.InProgress').value,
        icon: 'icon-inprogress-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.READY_FOR_PICKUP.name]: {
        text: $t('XeTransferModalHeader.ReadyForPickup').value,
        icon: 'icon-ready-for-pickup-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name]: {
        text: $t('XeTransferModalHeader.Completed').value,
        icon: 'icon-checkmark-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.PENDING_CANCELLATION.name]: {
        text: $t('XeTransferModalHeader.PendingCancellation').value,
        icon: 'icon-warning-circle',
      },
      [TRANSACTION_ACTIVITY_STATUSES.CANCELLED.name]: {
        text: $t('XeTransferModalHeader.Canceled').value,
        icon: 'icon-cancel-circle',
      },
    }

    const statusString = computed(() => {
      if (props.transaction.transactionActivityStatus) {
        return status[props.transaction.transactionActivityStatus].text
      }
      return status[TRANSACTION_ACTIVITY_STATUSES.CHANGE_IN_PROGRESS.name].text
    })
    const statusIcon = computed(() => {
      if (props.transaction.transactionActivityStatus) {
        return status[props.transaction.transactionActivityStatus].icon
      }
      return status[TRANSACTION_ACTIVITY_STATUSES.CHANGE_IN_PROGRESS.name].text
    })

    return { statusString, statusIcon, formatCurrency, locale }
  },
})
</script>

<style scoped>
.container {
  @apply flex flex-col items-center -mt-8 mb-6 gap-1;
}
.status-content {
  @apply flex flex-col gap-2 items-center;
}
</style>
