<template>
  <div>
    <AppAlert v-if="transaction.bankAccountXe.settlementWarningMessage" theme="blue" class="mb-6">
      <p class="flex justify-items-start">
        <IconExclamationMark class="mr-2 w-12" />
        <span v-html="transaction.bankAccountXe.settlementWarningMessage" />
      </p>
    </AppAlert>

    <AppAlert v-else theme="blue" class="mb-6">
      <p class="flex">
        <IconExclamationMark class="mr-2 w-12" />
        <span
          v-html="
            $t('XeBankTransferDetails.BankDetailsUnchangedAlert', {
              referenceNumber: transaction.orderNumber,
            }).value
          "
        />
      </p>
    </AppAlert>

    <p class="bank-details-text">
      {{ $t('ComponentXeTransferDetails.BankDetailsText', { paymentMethodString }).value }}
    </p>

    <div class="mobile-card">
      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>{{ $t('ComponentXeTransferDetails.BankDetailsYouPay').value }}</template>
          <template #content>
            <span>{{ transaction.amountTotal | currency(transaction.currencyFrom) }}</span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.amountTotal)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>{{ $t('ComponentXeTransferDetails.BankDetailsTo').value }}</template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.accountName }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.accountName)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsAddress').value }}
          </template>
          <template #content>
            <div class="company-address">
              <div v-if="transaction.addressXe.companyAddressOne">
                {{ transaction.addressXe.companyAddressOne }}
              </div>
              <div v-if="transaction.addressXe.companyAddressTwo">
                {{ transaction.addressXe.companyAddressTwo }}
              </div>
              <div v-if="transaction.addressXe.companyAddressThree">
                {{ transaction.addressXe.companyAddressThree }}
              </div>
              <div v-if="transaction.addressXe.companyAddressCountry">
                {{ transaction.addressXe.companyAddressCountry }}
              </div>
            </div>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.addressXe.address)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsReferenceNumber').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.orderNumber }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.orderNumber)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList v-if="transaction.bankAccountXe.accountNumber">
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsAccountNumber').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.accountNumber }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.accountNumber)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList v-if="transaction.bankAccountXe.ncc">
        <AppDescriptionListItem>
          <template #title>
            {{ transaction.bankAccountXe.nccName }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.ncc }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.ncc)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList v-if="transaction.bankAccountXe.iban">
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsIban').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.iban }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.iban)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList v-if="transaction.bankAccountXe.swiftBic">
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsBic').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.swiftBic }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.swiftBic)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsBankName').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.bankName }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.bankName)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>

      <AppDescriptionList>
        <AppDescriptionListItem>
          <template #title>
            {{ $t('ComponentXeTransferDetails.BankDetailsBankAddress').value }}
          </template>
          <template #content>
            <span>
              {{ transaction.bankAccountXe.address }}
            </span>
            <AppButton
              class="copy-icon"
              theme="icon"
              @click="copyClipboard(transaction.bankAccountXe.address)"
            >
              <AppIcon>
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </AppDescriptionList>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useI18nStore, useAuthStore } from '@galileo/stores'
import { IconCopy } from '@oen.web.vue2/icons'
import { IconExclamationMark } from '@galileo/assets/icons/vue'

import {
  AppAlert,
  AppDescriptionList,
  AppDescriptionListItem,
  AppButton,
  AppIcon,
  useAlert,
} from '@oen.web.vue2/ui'

export default {
  components: {
    AppAlert,
    AppDescriptionList,
    AppDescriptionListItem,
    AppButton,
    AppIcon,
    IconCopy,
    IconExclamationMark,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    const userCountry = authStore.userProfile.country
    const { add: addAlert } = useAlert()

    const paymentMethodString = computed(() => {
      if (userCountry === 'US') {
        return $t('ComponentXeBankTransferDetailsModal.WireTransfer').value
      } else {
        return $t('ComponentXeBankTransferDetailsModal.BankTransfer').value
      }
    })

    const copyClipboard = async (value) => {
      try {
        await navigator.clipboard.writeText(value)
        addAlert($t('PageActivity.CopyText').value)
      } catch (error) {
        addAlert($t('PageActivity.ErrorText').value, error)
      }
    }

    return { $t, paymentMethodString, copyClipboard }
  },
}
</script>

<style scoped>
.bank-details-text {
  @apply text-secondary-text my-2;
}

.copy-icon {
  @apply inline-block;
  @apply ml-2;
  @apply w-6 h-6;

  ::v-deep .button-inner {
    @apply w-auto !important;
    @apply h-auto !important;
    @apply flex w-auto h-auto rounded-none;
  }
}

.mobile-card {
  @apply py-6 px-3 mb-6 gap-2 bg-white rounded-2xl shadow-ria-1;
  border: 1px solid #f3f4f6;

  @screen sm {
    @apply py-0 px-0 mb-0 gap-0 border-none shadow-none rounded-none;
  }
}

.company-address {
  @apply flex flex-col;
}
</style>
