import { ref } from '@vue/composition-api'

import { PaymentMethod } from '@galileo/api/launchpad/transactions/_orderNumber/get'
import {
  TRANSACTION_STATUSES_STEPS,
  TRANSACTION_ACTIVITY_STATUSES,
  ACTION_REQUIRED,
} from '@galileo/constants/transactionStatuses'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'
import { useI18nStore, useAppStore } from '@galileo/stores'
import useTimelineCommon from '@galileo/composables/useTransferDetails/shared'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default (callbacks = null) => {
  const { $t } = useI18nStore()

  const {
    getTimelineFirstStepContent,
    format,
    timelineColor,
    timelineWarningMessage,
    availableTimelineColors,
    warningCTA_Text,
    warningCTA_callback,
  } = useTimelineCommon()

  const bankAccountSteps = ref([])
  const currentBankAccountStep = ref(0)
  const showBankDetails = ref(false)
  const showBankTransferDurationText = ref(false)

  const reset = () => {
    showBankDetails.value = false
    showBankTransferDurationText.value = false
  }

  //#region get populated bank account steps
  const getPopulatedBankAccountSteps = (transaction) => {
    const isOpenBanking = transaction.paymentMethod === PaymentMethod.OpenBanking

    const isActionRequired = transaction.actionsRequired !== 'None'

    const cancelled =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.CANCELLED.name
    if (cancelled) {
      return statusCancelled(transaction)
    }

    const cancelling =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.PENDING_CANCELLATION.name

    if (cancelling) {
      return statusCancelling(transaction)
    }

    const transferSent =
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name

    if (transferSent) {
      return statusSent(transaction)
    } else if (transaction.paymentMethod.value === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
      // Paying from balance must be in progress if it is not complete, hence the following status is returned
      return statusComplete(transaction, false)
    }

    const bankTransferNotReceived =
      (transaction.transactionDetailStatus ===
        TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name &&
        (transaction.transactionActivityStatus ===
          TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name ||
          transaction.actionsRequired.includes(
            TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name
          ))) ||
      transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name ||
      transaction.actionsRequired.includes(TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name)

    if (bankTransferNotReceived) {
      if (isOpenBanking) {
        if (
          (!isActionRequired &&
            transaction.transactionActivityStatus !==
              TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name) ||
          transaction.transactionActivityStatus === TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name
        ) {
          return statusProcessingOpenBanking(transaction)
        }
        return statusFailedOpenBanking(transaction)
      }
      return statusNotReceived(transaction)
    }

    const openBankingProcessing = [
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name,
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name,
      TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name,
      TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name,
    ].includes(transaction.transactionDetailStatus)

    if (isOpenBanking && !isActionRequired && openBankingProcessing) {
      return statusProcessingOpenBanking(transaction)
    }

    const processing = [
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name,
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name,
    ].includes(transaction.transactionDetailStatus)

    if (processing) {
      if (isOpenBanking) {
        return statusProcessingOpenBanking(transaction)
      }
      return statusProcessing(transaction)
    }

    const paymentReceived =
      !isActionRequired &&
      [
        TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name,
        TRANSACTION_STATUSES_STEPS.PAYMENT_RECEIVED.name,
        TRANSACTION_STATUSES_STEPS.AWAITING_RELEASE.name,
      ].includes(transaction.transactionDetailStatus)

    if (paymentReceived) {
      return statusComplete(transaction, false)
    }

    const bankPaymentCompleted =
      !transaction.actionsRequired.includes(TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name) &&
      TRANSACTION_STATUSES_STEPS.AWAITING_VERIFICATION.name &&
      (transaction.actionsRequired.includes(ACTION_REQUIRED.RECIPIENT_REQUIRED) ||
        transaction.actionsRequired.includes(ACTION_REQUIRED.EDD_OUTSTANDING))

    if (bankPaymentCompleted) {
      return statusComplete(transaction, true)
    }

    const holdOnAccount = [
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name,
      TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name,
      TRANSACTION_STATUSES_STEPS.AWAITING_FUNDS_INVESTIGATION.name,
    ].includes(transaction.transactionDetailStatus)

    if (holdOnAccount) {
      return statusHoldOnAccount(transaction)
    }
  }
  //#endregion

  const parseBankAccountTransactionDetails = (transaction) => {
    bankAccountSteps.value.push(...getPopulatedBankAccountSteps(transaction))
  }

  //----------------------------------- Get Steps -----------------------------------

  const statusComplete = (transaction, completed = true) => {
    let steps = [3]
    currentBankAccountStep.value = 1

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }
    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }

    if (completed) showBankTransferDurationText.value = true

    return steps
  }

  const statusNotReceived = (transaction) => {
    currentBankAccountStep.value = 1
    let steps = [3]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, false)

    if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
      steps[1] = {
        text: $t('PageSendMoneyFinished.TitleVoltPending').value,
      }
    } else {
      steps[1] = {
        text: $t('ComponentXeTransferDetails.WaitingForFunds').value,
      }
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }

    timelineColor.value = availableTimelineColors.YELLOW
    timelineWarningMessage.value = $t('ComponentXeTransferDetails.UseXeBankDetails').value
    warningCTA_Text.value = $t('ComponentXeTransferDetails.ViewXeBankDetails').value
    warningCTA_callback.value = callbacks?.value.SHOW_BANK_DETAILS
    return steps
  }

  const statusSent = (transaction) => {
    currentBankAccountStep.value = 2
    let steps = [3]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? format(transaction.datePaymentReceived).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    const hasPaymentSentDate = !!transaction.datePaymentSent

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
      description: hasPaymentSentDate && format(transaction.datePaymentSent).value,
    }
    showBankTransferDurationText.value = true

    timelineColor.value = availableTimelineColors.GREEN

    return steps
  }

  const statusHoldOnAccount = (transaction) => {
    currentBankAccountStep.value = 2
    let steps = [4]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
      steps[1] = {
        text: $t('PageSendMoneyFinished.TitleVoltPending').value,
      }
    } else {
      steps[1] = {
        text: $t('ComponentXeTransferDetails.weReceivedYourText', {
          currencyFrom: transaction.currencyFrom,
        }).value,
        description: hasPaymentDate
          ? $t('ComponentXeTransferDetails.paymentReceivedText', {
              date: format(transaction.datePaymentReceived).value,
            }).value
          : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
      }
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.moneyReadyTitleText').value,
      description: $t('ComponentXeTransferDetails.getMoneyReadyText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }

    steps[3] = {
      text: $t('ComponentXeTransferDetails.transferOnItsWay').value,
    }
    return steps
  }

  const statusProcessingOpenBanking = (transaction) => {
    currentBankAccountStep.value = 1
    let steps = [3]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.unclearedFundsText').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }

    return steps
  }

  const statusFailedOpenBanking = (transaction) => {
    currentBankAccountStep.value = 1
    let steps = [3]

    timelineColor.value = availableTimelineColors.RED
    timelineWarningMessage.value = $t('ComponentXeTransferDetails.ProblemWithYourTransfer').value //'There’s a problem with your transfer.'
    warningCTA_Text.value = $t('ComponentXeTransferDetails.ContactUs').value // 'Contact us'
    warningCTA_callback.value = () => {
      useAppStore().openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.CONTACT_DETAILS,
        article: HelpDesk[HelpDeskProperties.CONTACT_DETAILS],
      })
    }

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.openBankingPaymentFailed').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }
    return steps
  }

  const statusProcessing = (transaction) => {
    currentBankAccountStep.value = 1
    let steps = [3]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.unclearedFundsText').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }
    return steps
  }

  const statusCancelled = (transaction) => {
    currentBankAccountStep.value = 1

    let steps = [2]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)
    steps[1] = {
      text: $t('ComponentXeTransferDetails.transferCancelledText').value,
      description: $t('ComponentXeTransferDetails.transferCancelledDescription').value,
    }

    timelineColor.value = availableTimelineColors.RED

    return steps
  }

  const statusCancelling = (transaction) => {
    currentBankAccountStep.value = 1

    let steps = [2]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.transferCancelling').value,
      description: $t('ComponentXeTransferDetails.transferCancellingWithin24').value,
    }

    timelineColor.value = availableTimelineColors.YELLOW
    return steps
  }

  return {
    parseBankAccountTransactionDetails,
    bankAccountSteps,
    currentBankAccountStep,
    showBankDetails,
    showBankTransferDurationText,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
    reset,
  }
}
