import { ref } from '@vue/composition-api'
import { useI18nStore, useAuthStore, useAppStore } from '@galileo/stores'
import { PaymentMethod } from '@galileo/api/launchpad/transactions/_orderNumber/get'
import {
  TRANSACTION_STATUSES_STEPS,
  TRANSACTION_ACTIVITY_STATUSES,
} from '@galileo/constants/transactionStatuses'
import { useIntlDateTimeFormat } from 'vue-composable'
import { getLocaleFromCountry } from '@galileo/composables/useLocale'
import useTimelineCommon from './shared'

export default () => {
  const {
    CANCELLED,
    PENDING_CANCELLATION,
    UNCLEARED_FUNDS,
    UNCLEARED_FUNDS_RECEIVED,
    AWAITING_VERIFICATION,
    AWAITING_BALANCE,
    AWAITING_RELEASE,
    PAYMENT_RECEIVED,
    READY_FOR_RELEASE,
    COMPLETED,
    COMPLETE,
  } = TRANSACTION_STATUSES_STEPS

  const {
    getTimelineFirstStepContent,
    format,
    timelineColor,
    timelineWarningMessage,
    availableTimelineColors,
    warningCTA_Text,
    warningCTA_callback,
  } = useTimelineCommon()

  const { $t } = useI18nStore()
  const userCountry = useAuthStore().user.country
  const selectedLocale = ref(getLocaleFromCountry(userCountry))
  const mobileWalletSteps = ref([])
  const currentMobileWalletStep = ref(0)

  //#region Get current MW Step
  const getCurrentMobileWalletStep = (transaction) => {
    const status = transaction.transactionDetailStatus ?? ''

    switch (status) {
      case UNCLEARED_FUNDS_RECEIVED.name:
      case UNCLEARED_FUNDS.name:
        if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
          return 1
        }
        return UNCLEARED_FUNDS_RECEIVED.mobileWalletStep
      case AWAITING_VERIFICATION.name:
      case AWAITING_RELEASE.name:
      case PAYMENT_RECEIVED.name:
      case READY_FOR_RELEASE.name:
        if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
          return 1
        }
        return transaction.paymentMethod === PaymentMethod.DirectDebit ? 1 : 0
      case COMPLETED.name:
      case COMPLETE.name:
        if (transaction.paymentMethod === PaymentMethod.DirectDebit) {
          return COMPLETE.mobileWalletStep
        }
        if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
          return 2
        }
        return 1
      case AWAITING_BALANCE.name:
      case CANCELLED.name:
        if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
          return 1
        }
      default:
        return 0
    }
  }
  //#endregion

  const parseMobileWalletTransactionDetails = (transaction) => {
    //we are setting on which steps we are currently are
    currentMobileWalletStep.value = getCurrentMobileWalletStep(transaction)

    mobileWalletSteps.value.push(...getFilledMobileWalletSteps(transaction))
  }

  //---------------------------------- steps generation-------------------------------

  //#region gety filled MW timeline steps

  const getFilledMobileWalletSteps = (transaction) => {
    const status = transaction.transactionDetailStatus ?? ''

    //Check which payment method is and set mobileWalletSteps
    if (status === COMPLETED.name || status === COMPLETE.name) {
      return statusCompleted(transaction)
    }
    if (status === CANCELLED.name || status === PENDING_CANCELLATION.name) {
      return statusCancelled(transaction)
    }
    if (status !== CANCELLED.name) {
      if (transaction.paymentMethod === PaymentMethod.DirectDebit) {
        return getDirectDebitSteps(transaction)
      } else if (
        transaction.paymentMethod === PaymentMethod.CreditCard ||
        transaction.paymentMethod === PaymentMethod.DebitCard
      ) {
        return getCardSteps(transaction)
      } else if (transaction.paymentMethod === PaymentMethod.OpenBanking) {
        return getOpenBankingSteps(transaction)
      }
    }
    return []
  }

  //#endregion

  //#region get direct debit steps
  const getDirectDebitSteps = (transaction) => {
    let steps = [3]
    currentMobileWalletStep.value = 1

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.unclearedFundsText').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
      description: $t('ComponentXeTransferDetails.moneyOnItsWayToMessageText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
    }
    return steps
  }
  //#endregion

  //#region get card steps
  const getCardSteps = (transaction) => {
    currentMobileWalletStep.value = 1
    let steps = [3]

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? $t('ComponentXeTransferDetails.paymentReceivedText', {
            date: format(transaction.datePaymentReceived).value,
          }).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', { currencyTo: transaction.currencyTo })
        .value,
      description: $t('ComponentXeTransferDetails.moneyOnItsWayToMessageText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
    }

    return steps
  }
  //#endregion

  //#region get cancelled steps
  const statusCancelled = (transaction = null) => {
    let steps = [2]
    currentMobileWalletStep.value = 1
    timelineColor.value = availableTimelineColors.RED
    timelineWarningMessage.value = $t('ComponentXeTransferDetails.AlertRefund').value

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    steps[1] = {
      text: $t('ComponentXeTransferDetails.transferCancelledText').value,
      description: $t('ComponentXeTransferDetails.transferCancelledDescription').value,
    }

    return steps
  }
  //#endregion

  const statusCompleted = (transaction) => {
    currentMobileWalletStep.value = 2
    let steps = [3]
    timelineColor.value = availableTimelineColors.GREEN

    steps[0] = getTimelineFirstStepContent(steps[0], transaction, true)

    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[1] = {
      text: $t('ComponentXeTransferDetails.weReceivedYourText', {
        currencyFrom: transaction.currencyFrom,
      }).value,
      description: hasPaymentDate
        ? $t('ComponentXeTransferDetails.paymentReceivedText', {
            date: format(transaction.datePaymentReceived).value,
          }).value
        : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
    }
    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
      description: format(transaction.dateCreated).value,
    }

    return steps
  }

  const getOpenBankingFailedSteps = (transaction = null) => {
    let steps = [3]

    timelineColor.value = availableTimelineColors.RED
    timelineWarningMessage.value = $t('ComponentXeTransferDetails.ProblemWithYourTransfer').value //'There’s a problem with your transfer.'
    warningCTA_Text.value = $t('ComponentXeTransferDetails.ContactUs').value // 'Contact us'
    warningCTA_callback.value = () => {
      useAppStore().openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.CONTACT_DETAILS,
        article: HelpDesk[HelpDeskProperties.CONTACT_DETAILS],
      })
    }

    steps[0] = {
      text: $t('ComponentXeTransferDetails.youSetUpYourTransfer').value,
      description: format(transaction.dateCreated).value,
    }

    steps[1] = {
      text: $t('ComponentXeTransferDetails.paymentFailedText').value,
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
    }

    return steps
  }

  const getOpenBankingSteps = (transaction) => {
    let steps = [3]
    const hasPaymentDate = !!transaction.datePaymentReceived

    steps[0] = {
      text: $t('ComponentXeTransferDetails.yourTransferIsReady').value,
      description: format(transaction.dateCreated).value,
    }

    if (transaction.transactionDetailStatus === COMPLETED.name) {
      // we've got your money

      steps[1] = {
        text: $t('ComponentXeTransferDetails.weReceivedYourText', {
          currencyFrom: transaction.currencyFrom,
        }).value,
        description: hasPaymentDate
          ? $t('ComponentXeTransferDetails.paymentReceivedText', {
              date: format(transaction.datePaymentReceived).value,
            }).value
          : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
      }
    } else {
      steps[1] = {
        text: $t('ComponentXeTransferDetails.unclearedFundsText').value,
        description: hasPaymentDate
          ? $t('ComponentXeTransferDetails.paymentReceivedText', {
              date: format(transaction.datePaymentReceived).value,
            }).value
          : $t('ComponentXeTransferDetails.paymentReceivedTextNoDate').value,
      }
    }

    steps[2] = {
      text: $t('ComponentXeTransferDetails.weSentYourText', {
        currencyTo: transaction.currencyTo,
      }).value,
      description: $t('ComponentXeTransferDetails.moneyOnItsWayToMessageText', {
        recipientFirstName: transaction.recipientFirstName,
      }).value,
    }

    return steps
  }

  return {
    parseMobileWalletTransactionDetails,
    currentMobileWalletStep,
    mobileWalletSteps,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
  }
}
