import { DeliveryMethod } from '@galileo/models/Transaction/app'

import useBankAccountDetailsTimeline from '@galileo/composables/useTransferDetails/useBankAccountDetailsTimeline'
import useMobileWalletDetailsTimeline from '@galileo/composables/useTransferDetails/useMobileWalletDetailsTimeline'
import useCashPickupDetailsTimeline from '@galileo/composables/useTransferDetails/useCashPickupDetailsTimeline'
import useFundsOnBalanceDetailsTimeline from '@galileo/composables/useTransferDetails/useFundsOnBalanceDetails'

import { ref } from '@vue/composition-api'

export default (callbacks = ref(null)) => {
  const steps = ref([])
  const currentStep = ref(0)

  const bankAccountTimeline = useBankAccountDetailsTimeline(callbacks)
  const cashPickupTimeline = useCashPickupDetailsTimeline(callbacks)
  const mobileWalletTimeline = useMobileWalletDetailsTimeline(callbacks)
  const fundsOnBalanceTimeline = useFundsOnBalanceDetailsTimeline(callbacks)

  const getTransactionTimeline = (trx) => {
    let result = {
      specificSteps: [],
      specificCurrentStep: null,
      color: null,
      warningMessage: null,
      warningCta: null,
      warningCta_callback: null,
    }

    let activeTimeline

    switch (trx.deliveryMethod) {
      case DeliveryMethod.CashPickup:
        resetFlags()
        activeTimeline = cashPickupTimeline
        activeTimeline.parseCashPickupTransactionDetails(trx)
        result.specificSteps = activeTimeline.cashPickupSteps.value
        result.specificCurrentStep = activeTimeline.currentCashPickupStep.value
        break
      case DeliveryMethod.MobileWallet:
        resetFlags()
        activeTimeline = mobileWalletTimeline
        activeTimeline.parseMobileWalletTransactionDetails(trx)
        result.specificSteps = activeTimeline.mobileWalletSteps.value
        result.specificCurrentStep = activeTimeline.currentMobileWalletStep.value
        break
      case DeliveryMethod.BankDeposit:
        resetFlags()
        activeTimeline = bankAccountTimeline
        activeTimeline.parseBankAccountTransactionDetails(trx)
        result.specificSteps = activeTimeline.bankAccountSteps.value
        result.specificCurrentStep = activeTimeline.currentBankAccountStep.value
        break
      case DeliveryMethod.FundsOnBalance:
        resetFlags()
        activeTimeline = fundsOnBalanceTimeline
        activeTimeline.parseFundsOnBalanceTransactionDetails(trx)
        result.specificSteps = activeTimeline.fundsOnBalanceSteps.value
        result.specificCurrentStep = activeTimeline.currentFundsOnBalanceStep.value
        break
    }

    result.color = activeTimeline.timelineColor.value
    result.warningMessage = activeTimeline.timelineWarningMessage.value
    result.warningCta = activeTimeline.warningCTA_Text.value
    result.warningCta_callback = activeTimeline.warningCTA_callback.value

    steps.value = result.specificSteps
    currentStep.value = result.specificCurrentStep

    return result
  }

  const resetFlags = () => {
    bankAccountTimeline.reset()
  }

  return {
    getTransactionTimeline,
    showBankDetails: bankAccountTimeline.showBankDetails,
    showBankTransferDurationText: bankAccountTimeline.showBankTransferDurationText,
    steps,
    currentStep,
  }
}
