<template>
  <AppCard>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/activity-in-progress.svg')"
      :title="$t('PageSendMoneyFinished.FundBalanceBankTransferTitle').value"
      alt="Warning"
    >
      <div class="description">
        {{ $t('PageSendMoneyFinished.FundBalanceBankTransferDescription').value }}
      </div>
    </AppCardImageTitle>
    <ButtonGroup>
      <AppButton
        analytics-name="send-finished-bank-transfer-view-details"
        @click="$emit('viewBankDetails')"
      >
        {{ $t('PageSendMoneyFinished.ButtonBankTransferDetails', { paymentMethodString }).value }}
      </AppButton>
      <AppLink
        class="send-finished-send-go-to-activity"
        analytics-name="send-finished-send-go-to-activity"
        @click="$router.push('/Activity')"
      >
        {{ $t('ComponentBankTransferFundingFinished.ButtonGoToActivity').value }}
      </AppLink>
    </ButtonGroup>
  </AppCard>
</template>

<script>
import { computed } from '@vue/composition-api'
import { AppCard, AppButton, AppLink, AppCardImageTitle } from '@oen.web.vue2/ui'
import { useI18nStore, useAuthStore } from '@galileo/stores'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'

export default {
  name: 'BankTransferSendMoneyFinishedFunding',
  emits: ['viewBankDetails'],
  components: {
    AppCardImageTitle,
    ButtonGroup,
    AppButton,
    AppLink,
    AppCard,
  },
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    const paymentMethodString = computed(() => {
      const userCountry = authStore.userProfile.country
      if (userCountry === 'US') {
        return $t('PageSendMoneyFinished.WireTransfer').value
      } else {
        return $t('PageSendMoneyFinished.BankTransfer').value
      }
    })

    return { $t, paymentMethodString }
  },
}
</script>

<style scoped>
.send-finished-send-go-to-activity {
  @apply pt-7;
}
::v-deep .card-image-title img {
  width: 40px;
}
::v-deep .card-image-title {
  .card-image-title-title {
    @apply leading-8 text-2xl font-medium;
  }
}
</style>
