import { DeliveryMethod, PaymentMethod, TransactionStatus } from '@galileo/models/Transaction/app'
import { fromApi } from '@galileo/models/Country/countryMapping'
import BankAccount from '@galileo/models/Payment/BankAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'
import { maskFormatAbbreviated } from '../../composables/useMaskHelpers'
import Recipient from '@galileo/models/Recipient/app'
import Location from '@galileo/models/Agent/location'

export const TransactionStepType = {
  Created: {
    enumName: 'Created',
    headerKey: 'ComponentXeTransferDetails.Step1DetailsHeader',
    textKey: 'ComponentXeTransferDetails.Step1DetailsText',
  },
  Confirmed: {
    enumName: 'Confirmed',
    headerKey: 'ComponentXeTransferDetails.Step2DetailsHeader',
    textKey: 'ComponentXeTransferDetails.Step2DetailsText',
  },
  Sent: {
    enumName: 'Sent',
    headerKey: 'ComponentXeTransferDetails.Step3DetailsHeader',
    textKey: 'ComponentXeTransferDetails.Step3DetailsText',
  },
  Paid: {
    enumName: 'Paid',
    headerKey: 'ComponentXeTransferDetails.Step4DetailsHeader',
    textKey: 'ComponentXeTransferDetails.Step4DetailsText',
  },
}

export const CashPayoutType = {
  NotApplicable: {
    // Default, applies to non-office pickup delivery methods
    enumName: 'NotApplicable',
  },

  OpenPaymentCountry: {
    // Open payment country
    enumName: 'OpenPaymentCountry',
  },
  OpenPaymentCorrespondent: {
    // Open payment correspondent (agent)
    enumName: 'OpenPaymentCorrespondent',
  },
  LocationSpecific: {
    // Remittance has to be picked up at a specific location (point-to-point)
    enumName: 'LocationSpecific',
  },
  Walmart: {
    // Related to Another partner not related to RMT4
    enumName: 'Walmart',
  },
}
