import { PaymentMethod } from '@galileo/api/launchpad/transactions/_orderNumber/get'
import { useI18nStore, useAnalyticsStore, useAuthStore } from '@galileo/stores'
import { getLocaleFromCountry } from '@galileo/composables/useLocale'
import { useIntlDateTimeFormat } from 'vue-composable'
import { ref } from '@vue/composition-api'

export const availableTimelineColors = {
  GREEN: 'green',
  YELLOW: 'yellow',
  DEFAULT: 'default',
  RED: 'red',
}

export default function useTimelineCommon() {
  const timelineColor = ref(availableTimelineColors.DEFAULT)
  const timelineWarningMessage = ref(null)
  const warningCTA_Text = ref(null)
  const warningCTA_callback = ref(null)

  const { $t } = useI18nStore()
  const userCountry = useAuthStore().user.country
  const analyticsStore = useAnalyticsStore()

  const selectedLocale = ref(getLocaleFromCountry(userCountry))
  const { format } = useIntlDateTimeFormat(selectedLocale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })

  function getTimelineFirstStepContent(step, transaction, moneyReceived = true) {
    const correctedFirstStep = getMoneyStatusDescription(transaction, moneyReceived)
    step = Object.assign(step, correctedFirstStep)
    return step
  }

  function getMoneyStatusDescription(transaction, received = true) {
    let step = { description: '' }

    switch (transaction.paymentMethod) {
      case PaymentMethod.DirectDebit:
      //
      case PaymentMethod.CreditCard:
      case PaymentMethod.DebitCard:
      //
      case PaymentMethod.OpenBanking:
      //
      default:
        step.text = $t('ComponentXeTransferDetails.TransferSettedUp').value
        step.description = format(transaction.dateCreated).value
        break
    }

    return step
  }

  return {
    getTimelineFirstStepContent,
    format,
    analyticsStore,
    availableTimelineColors,
    timelineColor,
    timelineWarningMessage,
    warningCTA_Text,
    warningCTA_callback,
  }
}
